import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import LookupService from "../../services/lookup/lookup.service";
import PlayGameService from "../../services/playGame/playGame.service";
import TextField from "@mui/material/TextField";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import { Formik } from "formik";
import { IDeclareResultsRequestModel } from "../../interface/Request/IDeclareResultsRequestModel";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { IGameHistoryModel } from "../../interface/BusinessModels/IGameHistoryModel";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  GameResultType,
  SUPPORTED_FORMATS,
} from "../../constants/DBConstants.contant";
import OutlinedInput from "@mui/material/OutlinedInput";
import { LoadingContext } from "../../context/loading.context";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { ICreateNewGameRequestModel } from "../../interface/Request/ICreateNewGameRequestModel";
import Stack from "@mui/material/Stack";
import OnlinePredictionOutlinedIcon from "@mui/icons-material/OnlinePredictionOutlined";

function createData(
  playerId: number,
  playerStraight: number,
  playerRumble: number
) {
  return { playerId, playerStraight, playerRumble };
}

const DeclareResults: FunctionComponent<PageProps> = ({ title }) => {
  const [gamesConfig, setGamesConfig] = useState<IGamesConfigModel[]>();
  const [gameDates, setGameDates] = useState<IGameHistoryModel[]>();
  const _gamesConfigService = new GamesConfigService({});
  const _lookupService = new LookupService({});
  const _playGameService = new PlayGameService({});
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const [initialValues, setInitialValues] = useState<
    IDeclareResultsRequestModel
  >({
    gameId: 0,
    gameHistoryId: 0,
    firstPrizeResult: "",
    twoDownResult: "",
    file: "",
  });

  const [createGameInitialValues, setCreateGameInitialValues] = useState<
    ICreateNewGameRequestModel
  >({
    gameId: 0,
    gameHistoryId: 0,
  });

  const { setToastConfig, setOpen } = useContext(ToastContext);

  useEffect(() => {
    initializeFunctions();

    async function initializeFunctions() {
      await getAvailableGames();
    }
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  const getAvailableGames = async () => {
    try {
      const response: IApiResponse = await _gamesConfigService.getAvailableGames();
      setGamesConfig(response.response as IGamesConfigModel[]);
      getGameDates(response.response[0].id);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
  };

  const getGameDates = async (gameId: number) => {
    const gameDatesResponse: IApiResponse = await _gamesConfigService.getGameDates(
      gameId
    );
    var resultsNotDeclared = gameDatesResponse.response as IGameHistoryModel[];

    setInitialValues({
      ...initialValues,
      gameId: gameId,
      gameHistoryId: resultsNotDeclared ? resultsNotDeclared[0].id : 0,
    });

    setCreateGameInitialValues({
      gameId: gameId,
      gameHistoryId: resultsNotDeclared ? resultsNotDeclared[0].id : 0,
    });

    setGameDates(gameDatesResponse.response as IGameHistoryModel[]);
  };

  const DeclareResultsSchema = yup.object().shape({
    gameId: yup.number().required("This field is required"),
    gameHistoryId: yup.number().required("This field is required"),
    firstPrizeResult: yup
      .string()
      .required("First Prize Result is required")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
    twoDownResult: yup
      .string()
      .required("Two down result is required.")
      .min(2, "Must be exactly 2 digits")
      .max(2, "Must be exactly 2 digits"),
    file: yup
      .mixed()
      .test(
        "The file is too large",
        "The file is too large",
        (value: any) => !value || (value && value.size <= 1024 * (1024 * 4))
      )
      .test(
        "Only the following formats are accepted: .jpeg, .jpg, .png",
        "Only the following formats are accepted: .jpeg, .jpg, .png",
        (value: any) =>
          !value || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });

  const CreateGameSchema = yup.object().shape({
    gameId: yup.number().required("This field is required"),
  });

  const _handleUploadGameResultImage = async (values: any) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === "gameId" || key === "file") {
          formData.append(key, values[key]);
        }
      });
      var response: IApiResponse = await _lookupService.uploadGameResultImage(
        formData
      );
      if (!response.isSuccess) {
        setTimeout(() => {
          setToastConfig(ToastSeverity.Error, response.message, true);
        }, 1000);
      } else {
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
  };

  const _handleDeclareResults = async (values: IDeclareResultsRequestModel) => {
    try {
      const isValid = await DeclareResultsSchema.isValid(values);
      if (isValid) {
        if (values.file != "") {
          await _handleUploadGameResultImage(values);
        }
        setLoading(true);
        var response: IApiResponse = await _playGameService.declareResults({
          gameId: values.gameId,
          gameHistoryId: values.gameHistoryId,
          firstPrizeResult: values.firstPrizeResult,
          twoDownResult: values.twoDownResult,
        });
        if (!response.isSuccess) {
          setToastConfig(ToastSeverity.Error, response.message, true);
        } else {
          setAlertConfig({
            description: response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              navigate("/LotteryGameSummary");
            },
          });
        }
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
    setLoading(false);
  };

  const _handleNewGameCreation = async (values: ICreateNewGameRequestModel) => {
    try {
      const isValid = await CreateGameSchema.isValid(values);
      if (isValid) {
        setLoading(true);
        var response: IApiResponse = await _playGameService.createNewGameHistory(
          values
        );
        if (!response.isSuccess) {
          setToastConfig(ToastSeverity.Error, response.message, true);
        } else {
          setAlertConfig({
            description: response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              navigate("/dashboard");
            },
          });
        }
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard commonBgColorCard saperatorSpacing">
              <Typography className="cardTitle" style={{ marginBottom: 10 }}>
                Publish Results.
              </Typography>
              <Formik
                enableReinitialize
                initialValues={{ ...initialValues }}
                validationSchema={DeclareResultsSchema}
                onSubmit={(values, { setSubmitting }) => {
                  _handleDeclareResults(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="height100">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={3} lg={3}>
                        <FormControl fullWidth>
                          <InputLabel id="gameId">Lottery Game Type</InputLabel>
                          <Select
                            labelId="gameId"
                            id="gameId"
                            label="gameId"
                            value={values.gameId.toString()}
                            onChange={(evt: SelectChangeEvent) => {
                              getGameDates(+evt.target.value);
                              setFieldValue("gameId", evt.target.value);
                            }}
                          >
                            {gamesConfig &&
                              gamesConfig.map((option: IGamesConfigModel) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id.toString()}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <FormControl fullWidth>
                          <InputLabel id="gameHistoryId">Game Date</InputLabel>
                          <Select
                            labelId="gameHistoryId"
                            id="gameHistoryId"
                            label="gameHistoryId"
                            value={values.gameHistoryId.toString()}
                            onChange={(evt: SelectChangeEvent) => {
                              setFieldValue("gameHistoryId", evt.target.value);
                            }}
                          >
                            {gameDates &&
                              gameDates.map((option: IGameHistoryModel) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id.toString()}
                                  disabled={
                                    option.gameResultStatus ==
                                    GameResultType.Declared
                                  }
                                >
                                  {option.gameDrawDate}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            id="firstPrizeResult"
                            type={"number"}
                            label="First Prize Result"
                            onChange={(event: any) => {
                              if (event.target.value.length <= 6) {
                                setFieldValue(
                                  `firstPrizeResult`,
                                  event.target.value
                                );
                              }
                            }}
                            value={values.firstPrizeResult}
                            variant="outlined"
                          />
                          {errors.firstPrizeResult &&
                            touched.firstPrizeResult && (
                              <FormHelperText className="errorMessage">
                                {errors.firstPrizeResult}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={3} lg={3}>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            id="twoDownResult"
                            type={"number"}
                            label="2 DOWN Result"
                            onChange={(event: any) => {
                              if (event.target.value.length <= 2) {
                                setFieldValue(
                                  `twoDownResult`,
                                  event.target.value
                                );
                              }
                            }}
                            value={values.twoDownResult}
                            variant="outlined"
                          />
                          {errors.twoDownResult && touched.twoDownResult && (
                            <FormHelperText className="errorMessage">
                              {errors.twoDownResult}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <OutlinedInput
                            id="file"
                            type="file"
                            label="Offer Logo"
                            onChange={(event: any) => {
                              setFieldValue(
                                "file",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                        </FormControl>
                        {errors.file && (
                          <FormHelperText className="errorMessage">
                            {errors.file}
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        className="verticalCenter boxRight"
                      >
                        <Box className="floatRightBtn">
                          <Stack spacing={2} direction="row">
                            <Button
                              type="submit"
                              variant="contained"
                              className="primaryFillBtn floatR"
                            >
                              <span>
                                <FileUploadIcon className="customIcon" />{" "}
                                Publish Result
                              </span>
                            </Button>
                            <Button
                              type="button"
                              variant="outlined"
                              className="primaryLineBtn floatR"
                            >
                              <span>
                                <OnlinePredictionOutlinedIcon className="customIcon" />{" "}
                                View Prediction
                              </span>
                            </Button>
                          </Stack>
                          {/* <FormControl fullWidth>
                            <Button
                              type="submit"
                              variant="contained"
                              className="primaryFillBtn floatR"
                            >
                              <span>
                                <FileUploadIcon className="customIcon" />{" "}
                                Publish Results
                              </span>
                            </Button>
                          </FormControl> */}
                        </Box>
                      </Grid>
                    </Grid>
                    {alertConfig && alertConfig.isShow && (
                      <AlertDialog
                        alertConfig={alertConfig}
                        callBack={alertConfig.callBack}
                      />
                    )}
                  </form>
                )}
              </Formik>
            </Box>
            <Box className="commonBgCard commonBgColorCard saperatorSpacing">
              <Typography className="cardTitle">
                Create Game without declaring results.
              </Typography>
              <Formik
                enableReinitialize
                initialValues={{ ...createGameInitialValues }}
                validationSchema={CreateGameSchema}
                onSubmit={(values, { setSubmitting }) => {
                  _handleNewGameCreation(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="height100"
                    style={{ marginTop: 20 }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={3} lg={3}>
                        <FormControl fullWidth>
                          <InputLabel id="gameId">Lottery Game Type</InputLabel>
                          <Select
                            labelId="gameId"
                            id="gameId"
                            label="gameId"
                            value={values.gameId.toString()}
                            onChange={(evt: SelectChangeEvent) => {
                              getGameDates(+evt.target.value);
                              setFieldValue("gameId", evt.target.value);
                            }}
                          >
                            {gamesConfig &&
                              gamesConfig.map((option: IGamesConfigModel) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id.toString()}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <FormControl fullWidth>
                          <InputLabel id="gameHistoryId">Game Date</InputLabel>
                          <Select
                            labelId="gameHistoryId"
                            id="gameHistoryId"
                            label="gameHistoryId"
                            value={values.gameHistoryId.toString()}
                            onChange={(evt: SelectChangeEvent) => {
                              setFieldValue("gameHistoryId", evt.target.value);
                            }}
                          >
                            {gameDates &&
                              gameDates.map((option: IGameHistoryModel) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id.toString()}
                                  disabled={
                                    option.gameResultStatus ==
                                    GameResultType.Declared
                                  }
                                >
                                  {option.gameDrawDate}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        className="verticalCenter boxRight"
                      >
                        <Box className="floatRightBtn">
                          <FormControl fullWidth>
                            <Button
                              type="submit"
                              variant="contained"
                              className="primaryFillBtn floatR"
                            >
                              <span>
                                <AccountTreeOutlinedIcon
                                  style={{ marginRight: 5 }}
                                  className="customIcon"
                                />{" "}
                                Create Next Game
                              </span>
                            </Button>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    {alertConfig && alertConfig.isShow && (
                      <AlertDialog
                        alertConfig={alertConfig}
                        callBack={alertConfig.callBack}
                      />
                    )}
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default DeclareResults;
