import { Component } from "react";
import HttpHelperService from "../httpHelper.service";
import { IUserRegistrationModel } from "../../interface/BusinessModels/IUserRegistrationModel";
import {
  GAME_CONFIG_OPTIONS,
  GAME_CONFIG_RULES,
  GAME_DATES,
  GAME_OPTION_DISCOUNTS,
  GET_AVAILABLE_GAMES,
  GET_GAMESCONFIG,
  REGISTER,
} from "../../constants/EndPoints.constant";

class GamesConfigService extends Component {
  _httpHelperService = new HttpHelperService({});

  getGamesConfig = async () => {
    return await this._httpHelperService.get(GET_GAMESCONFIG);
  };

  getAvailableGames = async () => {
    return await this._httpHelperService.get(GET_AVAILABLE_GAMES);
  };

  getGameOptions = async (configGameId: number) => {
    return await this._httpHelperService.get(
      `${GAME_CONFIG_OPTIONS}?id=${configGameId}`
    );
  };

  getGameDiscount = async (gameOptionId: number) => {
    return await this._httpHelperService.get(
      `${GAME_OPTION_DISCOUNTS}?gameOptionId=${gameOptionId}`
    );
  };

  getGameRules = async (configGameId: number, gameOptionId: number) => {
    return await this._httpHelperService.get(
      `${GAME_CONFIG_RULES(configGameId, gameOptionId)}`
    );
  };

  getGameDates = async (configGameId: number) => {
    return await this._httpHelperService.get(
      `${GAME_DATES}?gameId=${configGameId}`
    );
  };
}

export default GamesConfigService;
