import React, { FunctionComponent, useEffect, useState } from "react";
// import ImageList from '@mui/material/ImageList';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//List Import
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/joy";

//Icons
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useDispatch } from "react-redux";
import { logOut, sessionExpired } from "../../actions/auth.actions";

//Import TopNav Css
import "../topNav/topNav.component.style.css";
import UserService from "../../services/user/user.service";
import { IWalletBalanceModel } from "../../interface/BusinessModels/IWalletBalanceModel";
import { useSelector } from "react-redux";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import PageProps from "../../models/PageProps.interface";
import { AxiosError, HttpStatusCode } from "axios";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import AlertDialog from "../alertDialog/alertDialog.component";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import IconButton from "@mui/material/IconButton";
import { getWalletBalance } from "../../actions/lookup.actions";
import GoogleTranslate from "../../screens/googleTranslate";


const TopNav: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutUser = () => dispatch<any>(logOut());
  const _userService = new UserService({});
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const { walletBalance } = useSelector((state: any) => state.lookupReducer);
  const getUserWalletBalance = () => dispatch<any>(getWalletBalance());
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const dispatchSessionExpired = () => dispatch<any>(sessionExpired());


  useEffect(() => {
    getUserWalletBalance();
  }, []);

  const reloadWindow = (values: any) => {
    window.location.reload();
  };

  return (
    <Box className="">
      
      <Box>
      
        {userInfo && userInfo.user && userInfo.user.userId != "" ? (
          <Grid container spacing={2}>
           
            <Grid item xs={12} md={6} lg={6}>
              <Box>
                <Typography className="pageTitle">{title}</Typography>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6} lg={6} className="headerItemsCenter">
            <GoogleTranslate />
              <Box className="headerItem">
                <Typography className="headerAppId">
                  ID:{userInfo.user.appId}
                </Typography>
              </Box>
              <Box className="headerItem">
                <IconButton
                  className="navBtn reloadBtn"
                  onClick={reloadWindow}
                  aria-label="Reload"
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  <CachedIcon />
                </IconButton>
              </Box>
              <Box className="topBalance navBtn headerItem">
                {/* <span className="currencySymbol"></span> */}
                <span>
                  <img
                    alt="Profile Photo"
                    className=""
                    src={require("../../assets/images/common/currencyIcon.png")}
                  />
                </span>
                <span>
                  {walletBalance.balance}
                  {/* {walletBalance.currency} */}
                </span>
              </Box>
              <Box className="userAccount">
                <Box className="userPhoto">
                  <img
                    alt="Profile Photo"
                    className=""
                    src={require("../../assets/images/common/profile_user.jpg")}
                  />
                </Box>
                <Box className="hoverCard">
                  <Box className="userName">
                    <Typography onClick={() => logoutUser()}>
                      <span className="floatL">
                        <LogoutRoundedIcon />
                      </span>
                      <span className="floatL">Logout</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={2} md={1} lg={1} className="headerItemsCenter" sx={{justifyContent:"end"}}>
           
            </Grid>
            <Grid item xs={4} md={2} lg={2} className="headerItemsCenter" sx={{justifyContent:"end"}}>
              
              
            </Grid>
            <Grid item xs={2} md={1} lg={1} className="headerItemsCenter" sx={{justifyContent:"center"}}>
              
            </Grid> */}
          </Grid>
        ) : (
          <Grid item xs={12} md={9} lg={8}>
            {/* <GoogleTranslate /> */}
            <Stack spacing={2} direction="row" className="floatR">
              <Button
                type="submit"
                variant="contained"
                className="primaryFillBtn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/login");
                }}
              >
                <span>Login</span>
               
              </Button>
              <Button
                type="submit"
                variant="outlined"
                className="primaryLineBtn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/signup");
                }}
              >
                <span>Signup</span>
              </Button>
            </Stack>
          </Grid>
        )}
      </Box>
      {alertConfig && alertConfig.isShow && (
        <AlertDialog
          alertConfig={alertConfig}
          callBack={alertConfig.callBack}
        />
      )}
    </Box>
  );
};

export default TopNav;
