import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";

//Form
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

//Normal Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Icons
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import LookupService from "../../services/lookup/lookup.service";
import { GAMESTATUS_CONFIG } from "../../constants/DBConstants.contant";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PlayGameService from "../../services/playGame/playGame.service";
import { ILotteryHistoryRequest } from "../../interface/Request/ILotteryHistoryRequest";
import { ILotteryHistoryResponseModel } from "../../interface/Response/ILotteryHistoryResponseModel";
import { ILotteryHistoryModel } from "../../interface/BusinessModels/ILotteryHistoryModel";
import { IPlayedNumberModel } from "../../interface/BusinessModels/IPlayedNumberModel";
import { IGameHistoryModel } from "../../interface/BusinessModels/IGameHistoryModel";
import { DEFAULT_PAGESIZE } from "../../constants/DBConstants.contant";
import { LoadingContext } from "../../context/loading.context";
import { API_ERROR_STANDARD_MESSAGE } from "../../constants/DBConstants.contant";

//Css Import
import "../lotteryPlayHistory/lotteryPlayHistory.screen.style.css";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import TextField from "@mui/material/TextField";

function createData(
  playerId: number,
  playerStraight: number,
  playerRumble: number
) {
  return { playerId, playerStraight, playerRumble };
}

const rows = [
  createData(6.0, 24, 4.0),
  createData(6.0, 24, 4.0),
  createData(6.0, 24, 4.0),
];

const LotteryPlayHistory: FunctionComponent<PageProps> = ({ title }) => {
  const [gamesConfig, setGamesConfig] = useState<IGamesConfigModel[]>();
  const [gameStatus, setGameStatus] = useState<ILookupModel[]>();
  const [gameDates, setGameDates] = useState<IGameHistoryModel[]>();
  const [
    playHistory,
    setPlayHistory,
  ] = useState<ILotteryHistoryResponseModel | null>();
  const [lotteryRequest, setLotteryRequest] = useState<ILotteryHistoryRequest>({
    gameId: -1,
    gameStatus: -1,
    gameHistoryId: -1,
    numberSearch: "",
    pageNumber: 1,
    pageSize: DEFAULT_PAGESIZE,
  });
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const _gamesConfigService = new GamesConfigService({});
  const _lookupService = new LookupService({});
  const _playGameService = new PlayGameService({});
  const { setLoading } = useContext(LoadingContext);
  useEffect(() => {
    initializeFunctions();

    async function initializeFunctions() {
      await getAvailableGames();
    }
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  const getAvailableGames = async () => {
    try {
      const response: IApiResponse = await _gamesConfigService.getAvailableGames();
      const gameStatusResponse: IApiResponse = await _lookupService.getConfigs(
        GAMESTATUS_CONFIG
      );

      setGamesConfig(response.response as IGamesConfigModel[]);
      setGameStatus(gameStatusResponse.response as ILookupModel[]);
      getGameDates(-1);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
  };

  const getGameDates = async (gameId: number) => {
    const gameDatesResponse: IApiResponse = await _gamesConfigService.getGameDates(
      gameId
    );
    setGameDates(gameDatesResponse.response as IGameHistoryModel[]);
  };

  const getPlayHistory = async () => {
    try {
      setPlayHistory(null);
      setLoading(true);
      const response: IApiResponse = await _playGameService.getGameHistory(
        lotteryRequest
      );

      setPlayHistory(response.response as ILotteryHistoryResponseModel);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPlayHistory();
  }, [lotteryRequest.pageSize]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setLotteryRequest({
      ...lotteryRequest,
      pageNumber: value,
    });
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard commonBgColorCard saperatorSpacing">
              <Grid container spacing={1}>
                <Grid item xs={12} md={2} lg={2}>
                  <FormControl fullWidth>
                    <InputLabel id="lotteryType">Lottery Game Type</InputLabel>
                    <Select
                      labelId="lotteryType"
                      id="lotteryType"
                      label="lotteryType"
                      value={lotteryRequest.gameId.toString()}
                      onChange={(evt: SelectChangeEvent) => {
                        getGameDates(+evt.target.value);
                        setLotteryRequest({
                          ...lotteryRequest,
                          gameId: +evt.target.value,
                          gameHistoryId: -1,
                        });
                      }}
                    >
                      <MenuItem value={-1}>All</MenuItem>
                      {gamesConfig &&
                        gamesConfig.map((option: IGamesConfigModel) => (
                          <MenuItem
                            key={option.id}
                            value={option.id.toString()}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <FormControl fullWidth>
                    <InputLabel id="lotteryGameId">Game Date</InputLabel>
                    <Select
                      labelId="lotteryGameId"
                      id="lotteryGameId"
                      label="lotteryGameId"
                      value={lotteryRequest.gameHistoryId.toString()}
                      onChange={(evt: SelectChangeEvent) => {
                        setLotteryRequest({
                          ...lotteryRequest,
                          gameHistoryId: +evt.target.value,
                        });
                      }}
                    >
                      <MenuItem value={-1}>All</MenuItem>
                      {gameDates &&
                        gameDates.map((option: IGameHistoryModel) => (
                          <MenuItem
                            key={option.id}
                            value={option.id.toString()}
                          >
                            {option.gameDrawDate}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <FormControl fullWidth>
                    <InputLabel id="gameStatus">Game Status</InputLabel>
                    <Select
                      labelId="gameStatus"
                      id="gameStatus"
                      label="gameStatus"
                      value={lotteryRequest.gameStatus.toString()}
                      onChange={(evt: SelectChangeEvent) => {
                        setLotteryRequest({
                          ...lotteryRequest,
                          gameStatus: +evt.target.value,
                          numberSearch: "",
                        });
                      }}
                    >
                      <MenuItem value={-1}>All</MenuItem>
                      <MenuItem value={-2}>Search Played Number</MenuItem>
                      {gameStatus &&
                        gameStatus.map((option: ILookupModel) => (
                          <MenuItem
                            key={option.id}
                            value={option.id.toString()}
                          >
                            {option.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2} lg={2} className="verticalCenter">
                  <FormControl fullWidth>
                    <TextField
                      type="number"
                      label="Search Played Number"
                      variant="outlined"
                      fullWidth
                      onChange={(evt) =>
                        setLotteryRequest({
                          ...lotteryRequest,
                          numberSearch: evt.target.value,
                        })
                      }
                      value={lotteryRequest.numberSearch}
                      disabled={lotteryRequest.gameStatus !== -2} // Disable If it is textbox Search
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={2}
                  className="verticalCenter"
                ></Grid>
                <Grid item xs={12} md={2} lg={2} className="verticalCenter">
                  <FormControl fullWidth>
                    <Button
                      variant="contained"
                      className="primaryFillBtn sectionBtn floatR"
                      onClick={() => getPlayHistory()}
                    >
                      <span className="floatL">
                        <DownloadForOfflineRoundedIcon className="customIcon" />{" "}
                        &nbsp; Get Results
                      </span>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box>
              {playHistory &&
              playHistory.lotteries &&
              playHistory.lotteries.length > 0 ? (
                <Box>
                  <Grid container xs={12}>
                    <Grid
                      container
                      xs={12}
                      justifyContent="space-between"
                      style={{
                        borderBottom: "1px solid black",
                        marginBottom: "20px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Select
                        className="commonSelect"
                        labelId="country"
                        id="country"
                        label="Country"
                        onChange={(evt: SelectChangeEvent) => {
                          setLotteryRequest({
                            ...lotteryRequest,
                            pageSize: +evt.target.value,
                          });
                        }}
                        value={lotteryRequest.pageSize.toString()}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                      <Pagination
                        count={playHistory.pagination.totalPages}
                        onChange={handleChange}
                        showFirstButton
                        showLastButton
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {playHistory.lotteries.map((x: ILotteryHistoryModel) => (
                      <Grid item xs={12} md={6} lg={4} key={x.id}>
                        <Box className="commonCard">
                          <Card>
                            <CardContent>
                              <Box className="commonBgCard saperatorSpacing">
                                <Grid container spacing={2}>
                                  <Grid item xs={10} md={10} lg={10}>
                                    <Typography className="cardTitle whiteColor">
                                      {x.ticketNumber.toUpperCase()}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    md={2}
                                    lg={2}
                                    className="boxRight"
                                  >
                                    <Box className="">
                                      <img
                                        alt="A2zlotto - Flag"
                                        className=""
                                        src={require("../../assets/images/lotteryHistory/thailand.png")}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Game
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.gameType}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Game Type
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.gameOption}
                                  </Typography>
                                </Box>
                                {/* <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Ticket Number
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.ticketNumber}
                                  </Typography>
                                </Box> */}
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Game Result
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.status}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Created On
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.createdOn}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Game Draw Date
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.gameDrawDate}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Box>
                                  <Typography className="sectionTitle saperatorBorder">
                                    Game Result
                                  </Typography>
                                </Box>
                                <Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Total Amount(Played)
                                    </Typography>
                                    <Typography className="commonValue">
                                      &nbsp;{x.playedAmount}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Total Amount(Paid)
                                    </Typography>
                                    <Typography className="commonValue">
                                      &nbsp;{x.paidAmount}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Discount Amount
                                    </Typography>
                                    <Typography className="commonValue">
                                      &nbsp;{x.discountAmount}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Discount Applied
                                    </Typography>
                                    <Typography className="commonValue">
                                      {x.discountApplied}&nbsp;%
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Won Amount
                                    </Typography>
                                    <Typography className="commonValue">
                                      &nbsp;{x.amountWon}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Agent Commission
                                    </Typography>
                                    <Typography className="commonValue">
                                      &nbsp;{x.agentCommission}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Final Winning Amount
                                    </Typography>
                                    <Typography className="commonValue">
                                      &nbsp;{x.finalWonAmount}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <Box>
                                  <Typography className="sectionTitle saperatorBorder">
                                    Numbers Played
                                  </Typography>
                                </Box>
                                <Box>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                      <TableContainer
                                        component={Paper}
                                        className="numbersPlayedTable"
                                      >
                                        <Table
                                          aria-label="Game Result Table"
                                          className="commonBasicTable"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell component="th">
                                                Number
                                              </TableCell>
                                              <TableCell component="th">
                                                Straight
                                              </TableCell>
                                              {x.isRumble && (
                                                <TableCell component="th">
                                                  Rumble
                                                </TableCell>
                                              )}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {x.playedNumbers.map(
                                              (row: IPlayedNumberModel) => (
                                                <TableRow
                                                  key={row.number}
                                                  sx={{
                                                    "&:last-child td, &:last-child th": {
                                                      border: 0,
                                                    },
                                                  }}
                                                >
                                                  <TableCell align="center">
                                                    {row.number}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {row.straight}
                                                  </TableCell>
                                                  {x.isRumble && (
                                                    <TableCell align="center">
                                                      {row.rumble}
                                                    </TableCell>
                                                  )}
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <Box className="noDataFound">
                  <Box className="noDataFoundPreview">
                    <img
                      alt="A2zlotto - Start a Game"
                      className="commonImage"
                      src={require("../../assets/images/lotteryHistory/playGame.png")}
                    />
                  </Box>
                  <Typography component="a" className="noDataFoundLabel">
                    Interested in starting a game? Please {""}
                    <Link href="/playGames">click here to begin.</Link>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default LotteryPlayHistory;
