import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/joy";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { ToastContext } from "../../context/toast.context";
import { ISaveBankAccountModel } from "../../interface/Request/ISaveBankAccountModel";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import WithdrawalService from "../../services/withdrawal/withdrawal.service";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import FormHelperText from "@mui/material/FormHelperText";
import { IWithdrawalModel } from "../../interface/BusinessModels/IWithdrawalModel";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
} from "../../constants/DBConstants.contant";
import AlertDialog from "../../components/alertDialog/alertDialog.component";

const AddBankAccount = (props: {
  editData: IWithdrawalModel | null | undefined;
  callBack: (response: IApiResponse) => null | void | undefined;
}) => {
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const _withdrawalService = new WithdrawalService({});
  const navigate = useNavigate();

  const InitialValues = {
    accountNumber:
      props.editData && props.editData.accountNumber
        ? props.editData.accountNumber
        : "",
    phoneNumber:
      props.editData && props.editData.phoneNumber
        ? props.editData.phoneNumber
        : "",
    accountHolderName:
      props.editData && props.editData.accountHolderName
        ? props.editData.accountHolderName
        : "",
    ifscCode:
      props.editData && props.editData.ifscCode ? props.editData.ifscCode : "",
  };

  useEffect(() => {
    if (props.editData !== null) {
      setIsEdit(true);
    }
  }, []);

  const AddBankAccountSchema = yup.object().shape({
    accountNumber: yup.number().required("This field is required"),
    phoneNumber: yup.number().required("This field is required"),
    accountHolderName: yup.string().required("This field is required"),
    ifscCode: yup
      .string()
      .min(11, "Should be minimum of 11 characters")
      .required("This field is required"),
  });

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const _handleSaveAccount = async (values: ISaveBankAccountModel) => {
    try {
      const isValid = await AddBankAccountSchema.isValid(values);
      if (isValid) {
        var response: IApiResponse = await _withdrawalService.saveAccountDetails(
          { ...values }
        );
        props.callBack(response);
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
  };

  const handleFormReset = (values: any) => {
    if (window.confirm("Do You Want To Reset?")) {
      values = { ...InitialValues };
    }
  };

  return (
    <Formik
      initialValues={{ ...InitialValues }}
      validationSchema={AddBankAccountSchema}
      onSubmit={(values, { setSubmitting }) => {
        _handleSaveAccount(values);
      }}
      onReset={handleFormReset}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Box id="modal-modal-description">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  id="accountNumber"
                  label="Account Number"
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  value={values.accountNumber}
                />
                {errors.accountNumber && touched.accountNumber && (
                  <FormHelperText className="errorMessage">
                    {errors.accountNumber}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  id="accountHolderName"
                  label="Account Holder Name"
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  value={values.accountHolderName}
                />
                {errors.accountHolderName && touched.accountHolderName && (
                  <FormHelperText className="errorMessage">
                    {errors.accountHolderName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  id="ifscCode"
                  label="IFSC Code"
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  value={values.ifscCode}
                />
                {errors.ifscCode && touched.ifscCode && (
                  <FormHelperText className="errorMessage">
                    {errors.ifscCode}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  id="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  value={values.phoneNumber}
                />
                {errors.phoneNumber && touched.phoneNumber && (
                  <FormHelperText className="errorMessage">
                    {errors.phoneNumber}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box id="modal-modal-footer" className="commonFooter">
            <Stack spacing={2} direction="row">
              {props.editData === null && (
                <Button
                  type="reset"
                  variant="outlined"
                  className="primaryLineBtn"
                  disabled={props.editData !== null}
                >
                  <span>Reset</span>
                </Button>
              )}

              <Button
                type="submit"
                variant="contained"
                className="primaryFillBtn"
                disabled={isSubmitting}
              >
                <span>Submit</span>
              </Button>
            </Stack>
          </Box>
          {alertConfig && alertConfig.isShow && (
            <AlertDialog
              alertConfig={alertConfig}
              callBack={alertConfig.callBack}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

export default AddBankAccount;
