import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

//Tabs
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Import Css
import "../gameSettings/gameSettings.screen.style.css";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { IPaymentSettings } from "../../interface/BusinessModels/IPaymentSettings";
import CardActions from "@mui/material/CardActions";
import { IGameSettings } from "../../interface/BusinessModels/IGameSettings";
import {
  API_ERROR_STANDARD_MESSAGE,
  APPLICATION_SETTINGS,
  DEFAULT_ALERT_CONFIG,
  PAYMENT_SETTINGS,
  PERMISSION_SETTINGS,
  SOCIAL_CONNECTION_SETTINGS,
} from "../../constants/DBConstants.contant";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import LookupService from "../../services/lookup/lookup.service";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import ApplicationHelperService from "../../services/ApplicationHelperService";
import FormHelperText from "@mui/material/FormHelperText";
import { IApplicationSettings } from "../../interface/BusinessModels/IApplicationSettings";
import { ISocialConnectionSettings } from "../../interface/BusinessModels/ISocialConnectionSettings";
import { IPermissionSettings } from "../../interface/BusinessModels/IPermissionSettings";

const GameSettings: FunctionComponent<PageProps> = ({ title }) => {
  //Tabs Related Script Goes Here
  const [tabValue, setTabValue] = React.useState(PAYMENT_SETTINGS);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const _lookupService = new LookupService({});
  const _applicationHelperService = new ApplicationHelperService({});
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const [
    PaymentSettingsInitialValues,
    setPaymentSettingsInitialValues,
  ] = useState<IPaymentSettings>({
    upiId: "",
    upiQRCode: "",
    bankAccountNo: "",
    accountHolderName: "",
    ifscCode: "",
    sendMoney: "",
    stcPayId: "",
    stcPayName: "",
    ncbAccountNo: "",
    ncbAccountName: "",
    alRajhiAccountNumber: "",
    alRajhiAccountName: "",
  });

  const [
    ApplicationSettingsInitialValues,
    setApplicationSettingsInitialValues,
  ] = useState<IApplicationSettings>({
    bonusByReferral: "",
    joiningBonus: "",
    minimumRecharge: "",
    maximumRecharge: "",
    minimumWithdraw: "",
    maximumWithdraw: "",
    transactionFee: "",
  });

  const [
    SocialConnectionSettingsInitialValues,
    setSocialConnectionSettingsInitialValues,
  ] = useState<ISocialConnectionSettings>({
    agentWhatsapp: "",
    whatsappLink: "",
    youtubeLink: "",
    telegramLink: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    emailLink: "",
    lotteryVideo: "",
  });
  const [isValueLoded, setIsValueLoded] = useState<boolean>(false);

  const [PermissionSettings, setPermissionSettings] = useState<
    IPermissionSettings
  >({
    rechargeAvailable: false,
    withdrawAvailable: false,
    canDownloadMobileApp: false,
    gamesAvailable: false,
    isDisabledRechargeForm: false,
  });

  useEffect(() => {
    setIsValueLoded(false);
    fetchSettings(tabValue);
  }, [tabValue]);

  useEffect(() => {
    fetchSettings(PERMISSION_SETTINGS);
  }, []);

  const fetchSettings = async (type: string) => {
    var response: IApiResponse = await _lookupService.getGameSettings(type, 0);
    if (!response.isSuccess) {
      setToastConfig(ToastSeverity.Error, response.message, true);
    } else {
      const settingsArray: IGameSettings[] = response.response as IGameSettings[];
      let settings: any = [];
      switch (type) {
        case PAYMENT_SETTINGS:
          settings = PaymentSettingsInitialValues;
          break;
        case APPLICATION_SETTINGS:
          settings = ApplicationSettingsInitialValues;
          break;
        case SOCIAL_CONNECTION_SETTINGS:
          settings = SocialConnectionSettingsInitialValues;
          break;
        case PERMISSION_SETTINGS:
          settings = PermissionSettings;
          break;
      }
      if (settingsArray) {
        for (let index = 0; index < settingsArray.length; index++) {
          const element = settingsArray[index];
          let key: any = await _applicationHelperService.getFormIdOnConfigKey(
            element.key
          );
          if (type === PERMISSION_SETTINGS) {
            settings[key] = element.value === "TRUE" ? true : false;
          } else {
            settings[key] = element.value;
          }
        }

        switch (type) {
          case PAYMENT_SETTINGS:
            setPaymentSettingsInitialValues(settings);
            break;
          case APPLICATION_SETTINGS:
            setApplicationSettingsInitialValues(settings);
            break;
          case SOCIAL_CONNECTION_SETTINGS:
            setSocialConnectionSettingsInitialValues(settings);
            break;
          case PERMISSION_SETTINGS:
            setPermissionSettings({ ...settings, ...PermissionSettings });
            break;
        }
      }

      setIsValueLoded(true);
    }
  };

  const PaymentSettingsSchema = yup.object().shape({
    upiId: yup.string(),
    upiQRCode: yup.string(),
    bankAccountNo: yup.string(),
    accountHolderName: yup.string(),
    ifscCode: yup.string(),
    sendMoney: yup.string(),
    stcPayId: yup.string(),
    stcPayName: yup.string(),
    ncbAccountNo: yup.string(),
    ncbAccountName: yup.string(),
    alRajhiAccountNumber: yup.string(),
    alRajhiAccountName: yup.string(),
  });

  const ApplicationSettingsSchema = yup.object().shape({
    bonusByReferral: yup.number().max(100, "Should be lessthan 100"),
    joiningBonus: yup.number(),
    minimumRecharge: yup.number(),
    maximumRecharge: yup.number(),
    minimumWithdraw: yup.number(),
    maximumWithdraw: yup.number(),
    transactionFee: yup.number().max(100, "Should be lessthan 100"),
  });

  const SocialConnectionSettingsSchema = yup.object().shape({
    agentWhatsapp: yup.string(),
    whatsappLink: yup.string(),
    youtubeLink: yup.string(),
    telegramLink: yup.string(),
    facebookLink: yup.string(),
    instagramLink: yup.string(),
    twitterLink: yup.string(),
    emailLink: yup.string(),
    lotteryVideo: yup.string(),
  });

  const _saveSettings = async (values: any, type: string) => {
    try {
      let settings: IGameSettings[] = [];
      for (const [key, value] of Object.entries(values)) {
        console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
        var databaseKey = await _applicationHelperService.getConfigKeyOnFormId(
          key
        );
        if (databaseKey) {
          const gameSettings: IGameSettings = {
            key: databaseKey,
            value: value as string,
            type: type,
          };
          settings.push(gameSettings);
        }
      }
      var response: IApiResponse = await _lookupService.manageSettings(0, {
        gameSettingsModels: settings,
      });
      if (!response.isSuccess) {
        setToastConfig(ToastSeverity.Error, response.message, true);
      } else {
        if (type !== PERMISSION_SETTINGS) {
          setAlertConfig({
            description: response.response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
            },
          });
        }
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
  };

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    var key = event.target.id;
    var input = { [key]: event.target.checked.toString().toUpperCase() };
    await _saveSettings(input, PERMISSION_SETTINGS);
    fetchSettings(PERMISSION_SETTINGS);
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard commonBgColorCard saperatorSpacing">
              <Typography className="bannerTitle">
                Permission Settings
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={4}>
                  <Box className="commonFormField">
                    <Typography className="floatL normalText">
                      Recharge Available
                    </Typography>
                    <FormControlLabel
                      className="commonSwitch"
                      control={
                        <Switch
                          id="rechargeAvailable"
                          checked={PermissionSettings.rechargeAvailable}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Box className="commonFormField">
                    <Typography className="floatL normalText">
                      Withdraw Available
                    </Typography>
                    <FormControlLabel
                      className="commonSwitch"
                      control={
                        <Switch
                          id="withdrawAvailable"
                          checked={PermissionSettings.withdrawAvailable}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Box className="commonFormField">
                    <Typography className="floatL normalText">
                      Can Download Mobile App
                    </Typography>
                    <FormControlLabel
                      className="commonSwitch"
                      control={
                        <Switch
                          id="canDownloadMobileApp"
                          checked={PermissionSettings.canDownloadMobileApp}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Box className="commonFormField">
                    <Typography className="floatL normalText">
                      Games Available
                    </Typography>
                    <FormControlLabel
                      className="commonSwitch"
                      control={
                        <Switch
                          id="gamesAvailable"
                          checked={PermissionSettings.gamesAvailable}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Box className="commonFormField">
                    <Typography className="floatL normalText">
                      Is Disabled Recharge Form
                    </Typography>
                    <FormControlLabel
                      className="commonSwitch"
                      control={
                        <Switch
                          id="isDisabledRechargeForm"
                          checked={PermissionSettings.isDisabledRechargeForm}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label=""
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Payment Settings" value={PAYMENT_SETTINGS} />
                    <Tab
                      label="Application Settings"
                      value={APPLICATION_SETTINGS}
                    />
                    <Tab
                      label="Social Connection Settings"
                      value={SOCIAL_CONNECTION_SETTINGS}
                    />
                  </TabList>
                </Box>
                <TabPanel value={PAYMENT_SETTINGS}>
                  {isValueLoded && (
                    <Formik
                      enableReinitialize
                      initialValues={PaymentSettingsInitialValues}
                      validationSchema={PaymentSettingsSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        _saveSettings(values, PAYMENT_SETTINGS);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className="height100">
                          <Box className="paymentSettings">
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="upiId"
                                    label="UPI ID"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.upiId}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="upiQRCode"
                                    label="UPI QA Code Image URL"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.upiQRCode}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="bankAccountNo"
                                    label="Bank A/C Number"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.bankAccountNo}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="accountHolderName"
                                    label="A/C Holder Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.accountHolderName}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="ifscCode"
                                    label="IFSC Code"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.ifscCode}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="sendMoney"
                                    label="Send Money To(Western Union)"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.sendMoney}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="stcPayId"
                                    label="STC Pay ID"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.stcPayId}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="stcPayName"
                                    label="STC Pay Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.stcPayName}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="ncbAccountNo"
                                    label="NCB A/C Number"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.ncbAccountNo}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="ncbAccountName"
                                    label="NCB A/C Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.ncbAccountName}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="alRajhiAccountNumber"
                                    label="AI Rajhi A/C Number"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.alRajhiAccountNumber}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="alRajhiAccountName"
                                    label="AI Rajhi A/C Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.alRajhiAccountName}
                                  />
                                </Box>
                              </Grid>
                            </Grid>

                            <CardActions className="floatRightBtn">
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={6} lg={6}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    className="primaryFillBtn width100"
                                  >
                                    <span>Submit</span>
                                  </Button>
                                </Grid>
                              </Grid>
                            </CardActions>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  )}
                </TabPanel>
                <TabPanel value={APPLICATION_SETTINGS}>
                  {isValueLoded && (
                    <Formik
                      enableReinitialize
                      initialValues={ApplicationSettingsInitialValues}
                      validationSchema={ApplicationSettingsSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        _saveSettings(values, APPLICATION_SETTINGS);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className="height100">
                          <Box className="applicationSettings">
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="bonusByReferral"
                                    label="Bonus By Referral Play(%)"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.bonusByReferral}
                                  />
                                </Box>
                                {errors.bonusByReferral &&
                                  touched.bonusByReferral && (
                                    <FormHelperText className="errorMessage">
                                      {errors.bonusByReferral}
                                    </FormHelperText>
                                  )}
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="joiningBonus"
                                    label="Joining Bonus"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.joiningBonus}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="minimumRecharge"
                                    label="Minimum Recharge"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.minimumRecharge}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="maximumRecharge"
                                    label="Maximum Recharge"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.maximumRecharge}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="minimumWithdraw"
                                    label="Minimum Withdraw"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.minimumWithdraw}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="maximumWithdraw"
                                    label="Maximum Withdraw"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.maximumWithdraw}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="transactionFee"
                                    label="Transaction Fee(%)"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.transactionFee}
                                  />
                                </Box>
                                {errors.transactionFee &&
                                  touched.transactionFee && (
                                    <FormHelperText className="errorMessage">
                                      {errors.transactionFee}
                                    </FormHelperText>
                                  )}
                              </Grid>
                            </Grid>
                            <CardActions className="floatRightBtn">
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={6} lg={6}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    className="primaryFillBtn width100"
                                  >
                                    <span>Submit</span>
                                  </Button>
                                </Grid>
                              </Grid>
                            </CardActions>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  )}
                </TabPanel>
                <TabPanel value={SOCIAL_CONNECTION_SETTINGS}>
                  {isValueLoded && (
                    <Formik
                      enableReinitialize
                      initialValues={SocialConnectionSettingsInitialValues}
                      validationSchema={SocialConnectionSettingsSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        _saveSettings(values, SOCIAL_CONNECTION_SETTINGS);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className="height100">
                          <Box className="socialConnection">
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="agentWhatsapp"
                                    label="Agent Whatsapp Number"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.agentWhatsapp}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="whatsappLink"
                                    label="Whatsapp Link"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.whatsappLink}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="youtubeLink"
                                    label="Youtube Link"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.youtubeLink}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="telegramLink"
                                    label="Telegram Link"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.telegramLink}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="facebookLink"
                                    label="Facebook Link"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.facebookLink}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="instagramLink"
                                    label="Instagram Link"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.instagramLink}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="twitterLink"
                                    label="Twitter Link"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.twitterLink}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="emailLink"
                                    label="Email Link"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.emailLink}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonFormField">
                                  <TextField
                                    id="lotteryVideo"
                                    label="Lottery Video URL in Home Page"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.lotteryVideo}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <CardActions className="floatRightBtn">
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={6} lg={6}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    className="primaryFillBtn width100"
                                  >
                                    <span>Submit</span>
                                  </Button>
                                </Grid>
                              </Grid>
                            </CardActions>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
            {alertConfig && alertConfig.isShow && (
              <AlertDialog
                alertConfig={alertConfig}
                callBack={alertConfig.callBack}
              />
            )}
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default GameSettings;
