import React, { FunctionComponent, useState } from "react";
import ImageList from "@mui/material/ImageList";
import Box from "@mui/material/Box";
import { Typography } from "@mui/joy";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { useNavigate } from "react-router-dom";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import CompareArrowsRoundedIcon from "@mui/icons-material/CompareArrowsRounded";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BoltIcon from "@mui/icons-material/Bolt";

//Icons Import
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

//Css Import
import "../alertDialog/alertDialog.component.style.css";

const AlertDialog = (props: {
  alertConfig: IAlertDialogConfig;
  callBack: () => void | undefined;
}) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const _closeModel = () => {
    setIsOpen(!isOpen);
    props.callBack();
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const GetIconOnToastType = (props: any) => {
    switch (props.toastType.toLowerCase()) {
      case ToastSeverity.Error:
        return <HighlightOffRoundedIcon className="errorColor" />;
      case ToastSeverity.Warning:
        return <WarningAmberRoundedIcon className="warningColor" />;
      case ToastSeverity.Success:
        return <CheckCircleOutlineRoundedIcon className="successColor" />;
      case ToastSeverity.Info:
        return <InfoOutlinedIcon className="infoColor" />;
      default:
        return <></>;
    }
  };

  return (
    <Box className="">
      <Dialog
        open={isOpen}
        className="alertModel"
        TransitionComponent={Transition}
        keepMounted
        onClose={_closeModel}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className="alertIcon">
          <GetIconOnToastType toastType={props.alertConfig.toastSeverity} />
        </Box>
        {props.alertConfig.title && (
          <DialogTitle>{props.alertConfig.title}</DialogTitle>
        )}
        <DialogContent className="alertDialogDescription">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="content"
          >
            {props.alertConfig.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alertFooter">
          <Button
            variant="outlined"
            onClick={_closeModel}
            className="alertAction"
          >
            {props.alertConfig.buttonTitle ? (
              props.alertConfig.buttonTitle
            ) : (
              <span>Okay!</span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AlertDialog;
