import { Component } from "react";
import moment from "moment";

class ApplicationHelperService extends Component {
  isValidateEmail = async (text: string) => {
    try {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(text) === false) {
        console.log("In-Valid Email in Application Helper Service");
        return false;
      } else {
        console.log("Valid Email in Application Helper Service");
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  getUserDateTimeFormat = (timestamp: any) => {
    return moment(new Date(timestamp)).format("DD-MM-YYYY hh:mm A"); // 12H clock (AM/PM)
  };

  getExpandedDateTimeFormat = (timestamp: any) => {
    return moment(new Date(timestamp)).format("MMMM DD, YYYY hh:mm A"); // 12H clock (AM/PM)
  };

  getFormatedDate = (date = new Date(), format = "YYYY/MM/DD") =>
    moment(date).format(format);

  getExpandedFormatedDate = (date = new Date(), format = "DD-MMM-YYYY") =>
    moment(date).format(format);

  getConfigKeyOnFormId = (id: string) => {
    let key = "";
    switch (id) {
      // Payment Settings
      case "upiId":
        key = "UPI_ID";
        break;
      case "upiQRCode":
        key = "UPI_QR_CODE";
        break;
      case "bankAccountNo":
        key = "BANK_ACCOUNT_NUMBER";
        break;
      case "acHolderName":
        key = "ACCOUNT_HOLDER_NAME";
        break;
      case "ifscCode":
        key = "IFSC_CODE";
        break;
      case "sendMoney":
        key = "SEND_MONEY";
        break;
      case "stcPayId":
        key = "STC_PAY_ID";
        break;
      case "stcPayName":
        key = "STC_PAY_NAME";
        break;
      case "ncbAccountNo":
        key = "NCB_ACCOUNT_NO";
        break;
      case "ncbAccountName":
        key = "NCB_ACCOUNT_NAME";
        break;
      case "alRajhiAccountNumber":
        key = "AL_RAJHI_ACCOUNT_NUMBER";
        break;
      case "alRajhiAccountName":
        key = "AL_RAJHI_ACCOUNT_NAME";
        break;
      // Application Settings
      case "bonusByReferral":
        key = "BONUS_BY_REFERRAL";
        break;
      case "joiningBonus":
        key = "JOINING_BONUS";
        break;
      case "minimumRecharge":
        key = "MINIMUM_RECHARGE";
        break;
      case "maximumRecharge":
        key = "MAXIMUM_RECHARGE";
        break;
      case "minimumWithdraw":
        key = "MINIMUM_WITHDRAW";
        break;
      case "maximumWithdraw":
        key = "MAXIMUM_WITHDRAW";
        break;
      case "transactionFee":
        key = "TRANSACTION_FEE";
        break;
      // Social Connection Settings
      case "agentWhatsapp":
        key = "AGENT_WHATSAPP";
        break;
      case "whatsappLink":
        key = "WHATSAPP_LINK";
        break;
      case "youtubeLink":
        key = "YOUTUBE_LINK";
        break;
      case "telegramLink":
        key = "TELEGRAM_LINK";
        break;
      case "facebookLink":
        key = "FACEBOOK_LINK";
        break;
      case "instagramLink":
        key = "INSTAGRAM_LINK";
        break;
      case "twitterLink":
        key = "TWITTER_LINK";
        break;
      case "emailLink":
        key = "EMAIL_LINK";
        break;
      case "lotteryVideo":
        key = "LOTTERY_VIDEO";
        break;
      // Permission Settings
      case "rechargeAvailable":
        key = "RECHARGE_AVAILABLE";
        break;
      case "withdrawAvailable":
        key = "WITHDRAW_AVAILABLE";
        break;
      case "canDownloadMobileApp":
        key = "CAN_DOWNLOAD_MOBILE_APP";
        break;
      case "gamesAvailable":
        key = "GAMES_AVAILABLE";
        break;
      case "isDisabledRechargeForm":
        key = "IS_DISABLED_RECHARGE_FORM";
        break;
      // Game Permissions
      case "gameAvailable":
        key = "GAME_AVAILABLE";
        break;
      case "runningLotteryGame":
        key = "RUNNING_LOTTERY_GAME";
        break;
      case "enableLastDayDiscounts":
        key = "ENABLE_LAST_DAY_DISCOUNTS";
        break;
      case "showGameWinnersListScroller":
        key = "SHOW_GAME_WINNERS_LIST_SCROLLER";
        break;
      case "singleDigitGameAvailable":
        key = "SINGLE_DIGIT_GAME_AVAILABLE";
        break;
      case "gameTotalAvailable":
        key = "GAME_TOTAL_AVAILABLE";
        break;
      // Game General Settings
      case "gameStopHour":
        key = "GAME_STOP_HOUR";
        break;
      case "gameDiscountStopHour":
        key = "GAME_DISCOUNT_STOP_HOUR";
        break;
      case "minAmountPlay":
        key = "MIN_AMOUNT_PLAY";
        break;
      case "agentCommission":
        key = "AGENT_COMMISSION";
        break;
      // Game Discount Settings
      case "firstPrizeDiscount":
        key = "FIRST_PRIZE_DISCOUNT";
        break;
      case "threeUpDiscount":
        key = "THREE_UP_DISCOUNT";
        break;
      case "twoUpDiscount":
        key = "TWO_UP_DISCOUNT";
        break;
      case "twoDownDiscount":
        key = "TWO_DOWN_DISCOUNT";
        break;
      case "threeUpSingleDigitDiscount":
        key = "THREE_UP_SINGLE_DIGIT_DISCOUNT";
        break;
      case "twoUpSingleDigitDiscount":
        key = "TWO_UP_SINGLE_DIGIT_DISCOUNT";
        break;
      case "twoDownSingleDigitDiscount":
        key = "TWO_DOWN_SINGLE_DIGIT_DISCOUNT";
        break;
      case "threeUpGameTotalDiscount":
        key = "THREE_UP_GAME_TOTAL_DISCOUNT";
        break;
      case "twoUpGameTotalDiscount":
        key = "TWO_UP_GAME_TOTAL_DISCOUNT";
        break;
      case "twoDownGameTotalDiscount":
        key = "TWO_DOWN_GAME_TOTAL_DISCOUNT";
        break;
      // Game Special Discount Settings
      case "firstPrizeSpecialDiscount":
        key = "FIRST_PRIZE_SPECIAL_DISCOUNT";
        break;
      case "threeUpSpecialDiscount":
        key = "THREE_UP_SPECIAL_DISCOUNT";
        break;
      case "twoUpSpecialDiscount":
        key = "TWO_UP_SPECIAL_DISCOUNT";
        break;
      case "twoDownSpecialDiscount":
        key = "TWO_DOWN_SPECIAL_DISCOUNT";
        break;
      case "threeUpSingleDigitSpecialDiscount":
        key = "THREE_UP_SINGLE_DIGIT_SPECIAL_DISCOUNT";
        break;
      case "twoUpSingleDigitSpecialDiscount":
        key = "TWO_UP_SINGLE_DIGIT_SPECIAL_DISCOUNT";
        break;
      case "twoDownSingleDigitSpecialDiscount":
        key = "TWO_DOWN_SINGLE_DIGIT_SPECIAL_DISCOUNT";
        break;
      case "threeUpGameTotalSpecialDiscount":
        key = "THREE_UP_GAME_TOTAL_SPECIAL_DISCOUNT";
        break;
      case "twoUpGameTotalSpecialDiscount":
        key = "TWO_UP_GAME_TOTAL_SPECIAL_DISCOUNT";
        break;
      case "twoDownGameTotalSpecialDiscount":
        key = "TWO_DOWN_GAME_TOTAL_SPECIAL_DISCOUNT";
        break;
      // Daily Game Winnings
      case "firstPrizeStraightWinAmount":
        key = "FIRST_PRIZE_STRAIGHT_WIN_AMOUNT";
        break;
      case "firstPrizeRumbleWinAmount":
        key = "FIRST_PRIZE_RUMBLE_WIN_AMOUNT";
        break;
      case "threeUpStraightWinAmount":
        key = "THREE_UP_STRAIGHT_WIN_AMOUNT";
        break;
      case "threeUpRumbleWinAmount":
        key = "THREE_UP_RUMBLE_WIN_AMOUNT";
        break;
      case "twoUpWinAmount":
        key = "TWO_UP_WIN_AMOUNT";
        break;
      case "twoDownWinAmount":
        key = "TWO_DOWN_WIN_AMOUNT";
        break;
      case "threeUpSingleDigitWinAmount":
        key = "THREE_UP_SINGLE_DIGIT_WIN_AMOUNT";
        break;
      case "twoUpSingleDigitWinAmount":
        key = "TWO_UP_SINGLE_DIGIT_WIN_AMOUNT";
        break;
      case "twoDownSingleDigitWinAmount":
        key = "TWO_DOWN_SINGLE_DIGIT_WIN_AMOUNT";
        break;
      case "threeUpTotalWinAmount":
        key = "THREE_UP_TOTAL_WIN_AMOUNT";
        break;
      case "twoUpTotalWinAmount":
        key = "TWO_UP_TOTAL_WIN_AMOUNT";
        break;
      case "twoDownTotalWinAmount":
        key = "TWO_DOWN_TOTAL_WIN_AMOUNT";
        break;
      default:
        key = "";
        break;
    }

    return key;
  };

  getFormIdOnConfigKey = (id: string) => {
    let key = "";
    switch (id) {
      // Payment Settings
      case "UPI_ID":
        key = "upiId";
        break;
      case "UPI_QR_CODE":
        key = "upiQRCode";
        break;
      case "BANK_ACCOUNT_NUMBER":
        key = "bankAccountNo";
        break;
      case "ACCOUNT_HOLDER_NAME":
        key = "acHolderName";
        break;
      case "IFSC_CODE":
        key = "ifscCode";
        break;
      case "SEND_MONEY":
        key = "sendMoney";
        break;
      case "STC_PAY_ID":
        key = "stcPayId";
        break;
      case "STC_PAY_NAME":
        key = "stcPayName";
        break;
      case "NCB_ACCOUNT_NO":
        key = "ncbAccountNo";
        break;
      case "NCB_ACCOUNT_NAME":
        key = "ncbAccountName";
        break;
      case "AL_RAJHI_ACCOUNT_NUMBER":
        key = "alRajhiAccountNumber";
        break;
      case "AL_RAJHI_ACCOUNT_NAME":
        key = "alRajhiAccountName";
        break;
      // Application Settings
      case "BONUS_BY_REFERRAL":
        key = "bonusByReferral";
        break;
      case "JOINING_BONUS":
        key = "joiningBonus";
        break;
      case "MINIMUM_RECHARGE":
        key = "minimumRecharge";
        break;
      case "MAXIMUM_RECHARGE":
        key = "maximumRecharge";
        break;
      case "MINIMUM_WITHDRAW":
        key = "minimumWithdraw";
        break;
      case "MAXIMUM_WITHDRAW":
        key = "maximumWithdraw";
        break;
      case "TRANSACTION_FEE":
        key = "transactionFee";
        break;
      // Social Connection Settings
      case "AGENT_WHATSAPP":
        key = "agentWhatsapp";
        break;
      case "WHATSAPP_LINK":
        key = "whatsappLink";
        break;
      case "YOUTUBE_LINK":
        key = "youtubeLink";
        break;
      case "TELEGRAM_LINK":
        key = "telegramLink";
        break;
      case "FACEBOOK_LINK":
        key = "facebookLink";
        break;
      case "INSTAGRAM_LINK":
        key = "instagramLink";
        break;
      case "TWITTER_LINK":
        key = "twitterLink";
        break;
      case "EMAIL_LINK":
        key = "emailLink";
        break;
      case "LOTTERY_VIDEO":
        key = "lotteryVideo";
        break;
      // Permission Settings
      case "RECHARGE_AVAILABLE":
        key = "rechargeAvailable";
        break;
      case "WITHDRAW_AVAILABLE":
        key = "withdrawAvailable";
        break;
      case "CAN_DOWNLOAD_MOBILE_APP":
        key = "canDownloadMobileApp";
        break;
      case "GAMES_AVAILABLE":
        key = "gamesAvailable";
        break;
      case "IS_DISABLED_RECHARGE_FORM":
        key = "isDisabledRechargeForm";
        break;
      // Game Permissions
      case "GAME_AVAILABLE":
        key = "gameAvailable";
        break;
      case "RUNNING_LOTTERY_GAME":
        key = "runningLotteryGame";
        break;
      case "ENABLE_LAST_DAY_DISCOUNTS":
        key = "enableLastDayDiscounts";
        break;
      case "SHOW_GAME_WINNERS_LIST_SCROLLER":
        key = "showGameWinnersListScroller";
        break;
      case "SINGLE_DIGIT_GAME_AVAILABLE":
        key = "singleDigitGameAvailable";
        break;
      case "GAME_TOTAL_AVAILABLE":
        key = "gameTotalAvailable";
        break;
      // Game General Settings
      case "GAME_STOP_HOUR":
        key = "gameStopHour";
        break;
      case "GAME_DISCOUNT_STOP_HOUR":
        key = "gameDiscountStopHour";
        break;
      case "MIN_AMOUNT_PLAY":
        key = "minAmountPlay";
        break;
      case "AGENT_COMMISSION":
        key = "agentCommission";
        break;
      // Game Discount Settings
      case "FIRST_PRIZE_DISCOUNT":
        key = "firstPrizeDiscount";
        break;
      case "THREE_UP_DISCOUNT":
        key = "threeUpDiscount";
        break;
      case "TWO_UP_DISCOUNT":
        key = "twoUpDiscount";
        break;
      case "TWO_DOWN_DISCOUNT":
        key = "twoDownDiscount";
        break;
      case "THREE_UP_SINGLE_DIGIT_DISCOUNT":
        key = "threeUpSingleDigitDiscount";
        break;
      case "TWO_UP_SINGLE_DIGIT_DISCOUNT":
        key = "twoUpSingleDigitDiscount";
        break;
      case "TWO_DOWN_SINGLE_DIGIT_DISCOUNT":
        key = "twoDownSingleDigitDiscount";
        break;
      case "THREE_UP_GAME_TOTAL_DISCOUNT":
        key = "threeUpGameTotalDiscount";
        break;
      case "TWO_UP_GAME_TOTAL_DISCOUNT":
        key = "twoUpGameTotalDiscount";
        break;
      case "TWO_DOWN_GAME_TOTAL_DISCOUNT":
        key = "twoDownGameTotalDiscount";
        break;
      // Game Special Discount Settings
      case "FIRST_PRIZE_SPECIAL_DISCOUNT":
        key = "firstPrizeSpecialDiscount";
        break;
      case "THREE_UP_SPECIAL_DISCOUNT":
        key = "threeUpSpecialDiscount";
        break;
      case "TWO_UP_SPECIAL_DISCOUNT":
        key = "twoUpSpecialDiscount";
        break;
      case "TWO_DOWN_SPECIAL_DISCOUNT":
        key = "twoDownSpecialDiscount";
        break;
      case "THREE_UP_SINGLE_DIGIT_SPECIAL_DISCOUNT":
        key = "threeUpSingleDigitSpecialDiscount";
        break;
      case "TWO_UP_SINGLE_DIGIT_SPECIAL_DISCOUNT":
        key = "twoUpSingleDigitSpecialDiscount";
        break;
      case "TWO_DOWN_SINGLE_DIGIT_SPECIAL_DISCOUNT":
        key = "twoDownSingleDigitSpecialDiscount";
        break;
      case "THREE_UP_GAME_TOTAL_SPECIAL_DISCOUNT":
        key = "threeUpGameTotalSpecialDiscount";
        break;
      case "TWO_UP_GAME_TOTAL_SPECIAL_DISCOUNT":
        key = "twoUpGameTotalSpecialDiscount";
        break;
      case "TWO_DOWN_GAME_TOTAL_SPECIAL_DISCOUNT":
        key = "twoDownGameTotalSpecialDiscount";
        break;
      // Daily Game Winnings
      case "FIRST_PRIZE_STRAIGHT_WIN_AMOUNT":
        key = "firstPrizeStraightWinAmount";
        break;
      case "FIRST_PRIZE_RUMBLE_WIN_AMOUNT":
        key = "firstPrizeRumbleWinAmount";
        break;
      case "THREE_UP_STRAIGHT_WIN_AMOUNT":
        key = "threeUpStraightWinAmount";
        break;
      case "THREE_UP_RUMBLE_WIN_AMOUNT":
        key = "threeUpRumbleWinAmount";
        break;
      case "TWO_UP_WIN_AMOUNT":
        key = "twoUpWinAmount";
        break;
      case "TWO_DOWN_WIN_AMOUNT":
        key = "twoDownWinAmount";
        break;
      case "THREE_UP_SINGLE_DIGIT_WIN_AMOUNT":
        key = "threeUpSingleDigitWinAmount";
        break;
      case "TWO_UP_SINGLE_DIGIT_WIN_AMOUNT":
        key = "twoUpSingleDigitWinAmount";
        break;
      case "TWO_DOWN_SINGLE_DIGIT_WIN_AMOUNT":
        key = "twoDownSingleDigitWinAmount";
        break;
      case "THREE_UP_TOTAL_WIN_AMOUNT":
        key = "threeUpTotalWinAmount";
        break;
      case "TWO_UP_TOTAL_WIN_AMOUNT":
        key = "twoUpTotalWinAmount";
        break;
      case "TWO_DOWN_TOTAL_WIN_AMOUNT":
        key = "twoDownTotalWinAmount";
        break;
      default:
        key = "";
        break;
    }

    return key;
  };
}

export default ApplicationHelperService;
