
import HttpHelperService from "../services/httpHelper.service";
import { useContext, createRef } from "react";
import {
 REFFERALS_SUCCESS
} from "../constants/Reducer.constant";
import axios from "axios";
import { Action, Dispatch } from "redux";
import { AnyAction } from "redux";
import { IApiResponse } from "../interface/Response/IApiResponse";
import ApplicationHelperService from "../services/ApplicationHelperService";
import { REFFERALS_DETAILS,GET_ALL_USERS } from "../constants/EndPoints.constant";
import {IRefferals } from "../interface/BusinessModels/IRefferalsModel"
import {IUserResponseModel } from "../interface/Response/IUserResponseModel";

const _httpHelperService = new HttpHelperService({});
const _applicationHelperService = new ApplicationHelperService({});

export const getRefferals = () => async (dispatch: Dispatch<Action>) => {
    try {
      _httpHelperService
        .get(`${REFFERALS_DETAILS}`)
        .then(async (response: IApiResponse) => {
            dispatch({
                type: REFFERALS_SUCCESS,
                payload: response.response as IRefferals[],
              });
        })
        .catch((error) => {
          console.log("Look Up Actions", error);
        });
    } catch (error) {
      console.log("Look Up Actions", error);
    }
  };