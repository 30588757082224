import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { useNavigate } from "react-router-dom";

//Gadgest - List
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";

//Form
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

//Modal
import Modal from "@mui/material/Modal";

//Icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";

//Component Import
import PaymentOptionModal from "../../components/paymentOptionModal/paymentOptionModal.component";
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";
import {
  getConfigSettings,
  getWalletBalance,
} from "../../actions/lookup.actions";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  PAYMENT_SETTINGS,
  PERMISSION_SETTINGS,
  RechargeStatus,
} from "../../constants/DBConstants.contant";
import { IRechargePayments } from "../../interface/BusinessModels/IRechargePayments";
import LookupService from "../../services/lookup/lookup.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import AlertDialog from "../../components/alertDialog/alertDialog.component";

//Css
import "../myWalletRecharge/myWalletRecharge.screen.style.css";
import { json } from "stream/consumers";
import UserService from "../../services/user/user.service";
import { IWalletBalanceModel } from "../../interface/BusinessModels/IWalletBalanceModel";
import RechargeService from "../../services/recharge/recharge.service";
import { LoadingContext } from "../../context/loading.context";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";

import { IApplicationAgentRegistrationModel } from "../../interface/BusinessModels/IApplicationAgentRegistrationModel";
import Link from "@mui/material/Link";
import { AppConfig } from "../../constants/AppConfig.constant";
import AgentUsers from "../../components/agentUsers/agentUsers.component";

const MyWalletRecharge: FunctionComponent<PageProps> = ({ title }) => {
  const [openAddCard, setOpenAddCard] = React.useState(false);
  const dispatch = useDispatch();
  const getUserWalletBalance = () => dispatch<any>(getWalletBalance());
  const { walletBalance } = useSelector((state: any) => state.lookupReducer);
  const handleOpenAddCard = () => setOpenAddCard(true);
  const handleCloseAddCard = () => setOpenAddCard(false);
  const _userService = new UserService({});
  const [isPayIconsShow, setisPayIconsShow] = useState(true);
  const [isPayOptionsShow, setisPayOptionsShow] = useState(true);
  const [paymentSettings, setpaymentSettings] = useState<any[]>([]);

  const [paymentName, setPaymentName] = useState("");

  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [rechargeAmount, setRechargeAmount] = useState<number>(0);
  const [rechargeFormDisabled, setRechargeFormDisabled] = useState<boolean>(
    false
  );
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const _rechargePaymentsService = new RechargeService({});
  const _lookupService = new LookupService({});
  const loadPaymentSettingsType = () =>
    dispatch<any>(getConfigSettings(PAYMENT_SETTINGS, 0));
  const { gameSettings } = useSelector((state: any) => state.lookupReducer);

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;

  useEffect(() => {
    loadPaymentSettingsType();
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    getUserWalletBalance();
    getGamesSettings();

    // async function getWalletBalance() {
    //   const response: IApiResponse = await _userService.walletBalance();
    //   setWalletBalance(response.response as IWalletBalanceModel);
    // }

    async function getGamesSettings() {
      try {
        setLoading(true);
        const gameGeneralSettings: IApiResponse = await _lookupService.getGameSettings(
          PERMISSION_SETTINGS,
          0
        );
        const isRechargeFormDisabled = gameGeneralSettings.response.filter(
          (x: ILookupModel) => x.key === "IS_DISABLED_RECHARGE_FORM"
        )[0].value;
        if (isRechargeFormDisabled) {
          if (isRechargeFormDisabled === "TRUE") {
            setRechargeFormDisabled(true);
            setisPayIconsShow(true);
          } else {
            setRechargeFormDisabled(false);
          }
        } else {
          setRechargeFormDisabled(false);
        }
      } catch (error) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }

      setLoading(false);
    }
  }, []);

  const [rechargePayment, setRechargePayment] = useState<IRechargePayments>({
    id: 0,
    rechargeId: "",
    userId: 0,
    transactionType: "",
    transactionFields: "",
    amount: 0.0,
    transactionUTRId: "",
    phoneNumber: "",
    rechargeStatusId: RechargeStatus.Pending,
    type: "Recharge",
    comment: "",
    createdOn: new Date(),
  });

  const RechargeAmount = (number: React.SetStateAction<number>) => {
    setRechargeAmount(number);
    setisPayIconsShow(false);
  };

  const aggreeConditon = () => {
    setisPayOptionsShow(false);
    setOpenAddCard(false);
  };

  const filterDataByKey = (data: any[], key: string) => {
    return data.filter((item) =>
      item.key.toLowerCase().startsWith(key.toLowerCase())
    );
  };

  const openbankConditionsPopUp = (bankName: string) => {
    setPaymentName(bankName);
    var filteredData = filterDataByKey(gameSettings, bankName);
    console.log("filteredData:" + filteredData);
    setRechargePayment((prevPayment) => ({
      ...prevPayment,
      transactionType: bankName,
      amount: rechargeAmount,
      transactionFields: JSON.stringify(filteredData).toString(),
      rechargeStatusId: RechargeStatus.Pending,
      type: "Recharge",
    }));
    setpaymentSettings(filteredData);
    setOpenAddCard(true);
  };

  const setpaymentUTR = (value: string) => {
    setRechargePayment((prevPayment) => ({
      ...prevPayment,
      transactionUTRId: value,
    }));
  };

  const setPhonenumber = (value: string) => {
    setRechargePayment((prevPayment) => ({
      ...prevPayment,
      phoneNumber: value,
    }));
  };

  const closepopUp = () => {
    setOpenAddCard(false);
  };

  const _handleSaveRechargePayments = async (values: IRechargePayments) => {
    try {
      var response: IApiResponse = await _rechargePaymentsService.rechargePayments(
        {
          ...values,
          ...rechargePayment,
        }
      );
      if (+response.response.status <= 0) {
        setToastConfig(ToastSeverity.Error, response.response.message, true);
      } else {
        setAlertConfig({
          description: response.response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            navigate(`/rechargeDetails?rchId=${response.response.description}`);
          },
        });
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
  };
  const findFieldByKey = (key: any) => {
    return gameSettings.find((field: { key: any }) => field.key === key);
  };
  const renderListItem = (
    key: any,
    label: any,
    imageName: any,
    onClickHandler: React.MouseEventHandler<HTMLButtonElement> | undefined
  ) => {
    const field = findFieldByKey(key);
    if (field && field.value) {
      return (
        <ListItem className="commonListItem">
          <ListItemAvatar>
            <Avatar className="listAvtar">
              <Button
                onClick={() =>
                  onClickHandler === undefined
                    ? console.log("Clicked")
                    : onClickHandler
                }
              >
                <img
                  className="width100"
                  src={require(`../../assets/images/home/Payment_${imageName}.png`)}
                  alt={`Payment ${label}`}
                />
              </Button>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            className="listAvtarTitle"
            primary={`Payment ${label}`}
          />
        </ListItem>
      );
    }
    return null;
  };

  const ShowPaymentIcons = () => {};

  return (
    <Formik
      initialValues={rechargePayment}
      onSubmit={(values, { setSubmitting }) => {
        _handleSaveRechargePayments(values);
        setSubmitting(false); // Indicate submission is complete
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,

        /* and other goodies */
      }) => (
        <Box className="pageMain walletRecharge">
          <Box className="pageLeft">
            <LeftMenu />
          </Box>
          <Box className="pageRight">
            <Box className="pageHead">
              <TopNav title={title.split("|")[1].trim()} />
            </Box>
            <Box className="pageView">
              <Box className="pageViewBody commonScroll">
                <form onSubmit={handleSubmit}>
                  {!rechargeFormDisabled ? (
                    <Box
                      className="commonBgCard saperatorSpacing"
                      display="none"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={7} lg={7}>
                          <Box className="commonCenterCard">
                            <Box className="saperatorSpacing">
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    type={"number"}
                                    id="rechargeAmount"
                                    label="Recharge Amount"
                                    variant="outlined"
                                    fullWidth
                                    value={rechargeAmount ? rechargeAmount : ""}
                                    onChange={(event: any) =>
                                      RechargeAmount(+event.target.value)
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Grid
                              container
                              spacing={2}
                              className="saperatorSpacing"
                            >
                              <Grid item xs={4} md={4} lg={4}>
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => RechargeAmount(500)}
                                >
                                  <span>500</span>
                                </Button>
                              </Grid>
                              <Grid item xs={4} md={4} lg={4}>
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => RechargeAmount(1000)}
                                >
                                  <span>1000</span>
                                </Button>
                              </Grid>
                              <Grid item xs={4} md={4} lg={4}>
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => RechargeAmount(2000)}
                                >
                                  <span>2000</span>
                                </Button>
                              </Grid>
                              <Grid item xs={4} md={4} lg={4}>
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => RechargeAmount(5000)}
                                >
                                  <span>5000</span>
                                </Button>
                              </Grid>
                              <Grid item xs={4} md={4} lg={4}>
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => RechargeAmount(10000)}
                                >
                                  <span>10000</span>
                                </Button>
                              </Grid>
                              <Grid item xs={4} md={4} lg={4}>
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => RechargeAmount(20000)}
                                >
                                  <span>20000</span>
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={5} lg={5} className="walletBox">
                          <Box className="bannerImage">
                            <img
                              alt="A2zlotto - Wallet"
                              className=""
                              src={require("../../assets/images/myWallet/walletAmount.png")}
                            />
                          </Box>
                          <Typography className="amountTitle">
                            Available Balance
                          </Typography>
                          <Box>
                            <Typography className="amount">
                              {walletBalance.balance}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Box>
                      <Box className="commonBgCard saperatorSpacing agentsTeam">
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="verticalCenter"
                          >
                            <Box className="commonCenterCard">
                              {" "}
                              <Typography className="sectionTitle whiteColor">
                                Please contact below {AppConfig.applicationName}{" "}
                                support team to recharge your wallet.
                              </Typography>
                            </Box>
                          </Grid>
                          {/* <Grid
                            item
                            xs={12}
                            md={4}
                            lg={4}
                            className="walletBox"
                          >
                            <Box className="bannerImage">
                              <img
                                alt="A2zlotto - Wallet"
                                className=""
                                src={require("../../assets/images/myWallet/recharge.png")}
                              />
                            </Box>
                          </Grid> */}
                        </Grid>
                      </Box>
                      {/* Agents Team Section Starts Here */}
                      <Box>
                        <div>
                          <Typography className="sectionTitle">
                            Available payments options.
                          </Typography>
                          <Box className="paymentOption">
                            <List>
                              {renderListItem(
                                "UPI_ID",
                                "UPI",
                                "UPI",
                                handleOpenAddCard
                              )}
                              {renderListItem(
                                "BANK_ACCOUNT_NUMBER",
                                "Bank",
                                "Bank",
                                undefined
                              )}
                              {renderListItem(
                                "STC_PAY_ID",
                                "STC Pay",
                                "StcPay",
                                undefined
                              )}
                              {renderListItem(
                                "NCB_ACCOUNT_NO",
                                "NCB",
                                "NCB",
                                undefined
                              )}
                              {renderListItem(
                                "AL_RAJHI_ACCOUNT_NUMBER",
                                "Al Rajhi Bank",
                                "AlRajhiBank",
                                undefined
                              )}
                              {renderListItem(
                                "Western Unio",
                                "Western Union",
                                "WesternUnion",
                                undefined
                              )}
                              {renderListItem(
                                "E_VOUCHER",
                                "EVoucher",
                                "EVoucher",
                                undefined
                              )}
                              {renderListItem(
                                "MASTER_CARD",
                                "MasterCard",
                                "MasterCard",
                                undefined
                              )}
                              {renderListItem(
                                "VISA",
                                "Visa",
                                "Visa",
                                undefined
                              )}
                              {renderListItem(
                                "PAYPAL",
                                "PayPal",
                                "PayPal",
                                undefined
                              )}
                              {renderListItem(
                                "CRYPTO",
                                "Crypto",
                                "Crypto",
                                undefined
                              )}
                            </List>
                          </Box>
                        </div>
                        <AgentUsers />
                      </Box>
                      {/* Agents Team Section Ends Here */}
                    </Box>
                  )}

                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}></Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Box>
                      <div hidden={isPayIconsShow}>
                        <Typography className="sectionTitle">
                          Available payments options.
                        </Typography>
                        <Box className="paymentOption">
                          <List>
                            {renderListItem("UPI_ID", "UPI", "UPI", () =>
                              openbankConditionsPopUp("UPI")
                            )}
                            {renderListItem(
                              "BANK_ACCOUNT_NUMBER",
                              "Bank",
                              "Bank",
                              () => openbankConditionsPopUp("BANK")
                            )}
                            {renderListItem(
                              "STC_PAY_ID",
                              "STC Pay",
                              "StcPay",
                              () => openbankConditionsPopUp("STC")
                            )}
                            {renderListItem(
                              "NCB_ACCOUNT_NO",
                              "NCB",
                              "NCB",
                              () => openbankConditionsPopUp("NCB")
                            )}
                            {renderListItem(
                              "AL_RAJHI_ACCOUNT_NUMBER",
                              "Al Rajhi Bank",
                              "AlRajhiBank",
                              () => openbankConditionsPopUp("AL_RAJHI")
                            )}
                            {renderListItem(
                              "Western Unio",
                              "Western Union",
                              "WesternUnion",
                              () => openbankConditionsPopUp("Western Unio")
                            )}
                            {renderListItem(
                              "E_VOUCHER",
                              "EVoucher",
                              "EVoucher",
                              handleOpenAddCard
                            )}
                            {renderListItem(
                              "MASTER_CARD",
                              "MasterCard",
                              "MasterCard",
                              handleOpenAddCard
                            )}
                            {renderListItem(
                              "VISA",
                              "Visa",
                              "Visa",
                              handleOpenAddCard
                            )}
                            {renderListItem(
                              "PAYPAL",
                              "PayPal",
                              "PayPal",
                              handleOpenAddCard
                            )}
                            {renderListItem(
                              "CRYPTO",
                              "Crypto",
                              "Crypto",
                              handleOpenAddCard
                            )}
                          </List>
                        </Box>
                      </div>

                      <div hidden={isPayOptionsShow}>
                        <Box className="commonCard">
                          <Box className="cardSpacing">
                            <Typography className="sectionTitle">
                              Payment Amount
                            </Typography>
                            <Typography>
                              <span></span>
                              <span>{rechargeAmount}</span>
                            </Typography>
                          </Box>

                          <Box className="payToID cardSpacing">
                            <Typography className="sectionTitle">
                              Payment Settings
                            </Typography>
                            {paymentSettings &&
                              paymentSettings.map((item) => (
                                <div key={item.id}>
                                  <Typography className="sectionTitle">
                                    {item.key}
                                  </Typography>
                                  <Typography className="paymentType">
                                    {item.value}
                                  </Typography>
                                </div>
                              ))}
                            {/* <Button
                              variant="contained"
                              className="primaryFillBtn"
                              onClick={() => {navigator.clipboard.writeText("")}}
                            >
                              Copy {paymentName} Number
                            </Button> */}
                          </Box>

                          <Box className="cardSpacing">
                            <Box className="paymentSteps saperationSectionMedium">
                              <Typography>
                                <b>Step 1</b> Open online banking or payment
                                applications, transfer amount to the UPI
                                mentioned above.
                              </Typography>
                              <Typography>
                                <b>Step 2</b> Please submit reference number and
                                your phone number to proceed.
                              </Typography>
                            </Box>
                            <Box className="paymentReferenceInfo">
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    type="text"
                                    id="transactionUTRId"
                                    name="transactionUTRId"
                                    label="Enter Transaction ID/UTR No."
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      rechargePayment &&
                                      rechargePayment.transactionUTRId &&
                                      rechargePayment.transactionUTRId.toUpperCase()
                                    }
                                    onKeyDown={(event) => {
                                      if (
                                        !ALPHA_NUMERIC_DASH_REGEX.test(
                                          event.key
                                        )
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onChange={(e) =>
                                      setpaymentUTR(e.target.value)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    label="Phone Number"
                                    variant="outlined"
                                    fullWidth
                                    value={rechargePayment.phoneNumber}
                                    onChange={(e) => {
                                      const re = /^[0-9\b]+$/;
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        setPhonenumber(e.target.value);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Typography className="saperationSectionMedium">
                                You must fill in the correct Ref Number, then
                                click the button below to submit and wait for it
                                to approve.
                              </Typography>
                              <Button
                                type="submit"
                                variant="contained"
                                className="primaryFillBtn"
                                disabled={isSubmitting}
                              >
                                <span>Submit</span>
                              </Button>
                            </Box>
                          </Box>
                          <Box className="cardSpacing">
                            <Typography className="sectionTitle">
                              Reference Number Examples
                            </Typography>
                            <Box>
                              <Marquee>
                                <Box className="paymentScreens">
                                  <img
                                    alt=""
                                    className="commonImage"
                                    src={require("../../assets/images/paymentReference/PaymentScreenShot1.png")}
                                  />
                                </Box>
                                <Box className="paymentScreens">
                                  <img
                                    alt=""
                                    className="commonImage"
                                    src={require("../../assets/images/paymentReference/PaymentScreenShot2.png")}
                                  />
                                </Box>
                                <Box className="paymentScreens">
                                  <img
                                    alt=""
                                    className="commonImage"
                                    src={require("../../assets/images/paymentReference/PaymentScreenShot3.png")}
                                  />
                                </Box>
                                <Box className="paymentScreens">
                                  <img
                                    alt=""
                                    className="commonImage"
                                    src={require("../../assets/images/paymentReference/PaymentScreenShot4.png")}
                                  />
                                </Box>
                                <Box className="paymentScreens">
                                  <img
                                    alt=""
                                    className="commonImage"
                                    src={require("../../assets/images/paymentReference/PaymentScreenShot5.png")}
                                  />
                                </Box>
                                <Box className="paymentScreens">
                                  <img
                                    alt=""
                                    className="commonImage"
                                    src={require("../../assets/images/paymentReference/PaymentScreenShot6.png")}
                                  />
                                </Box>
                              </Marquee>
                            </Box>
                          </Box>
                        </Box>
                      </div>
                    </Box>
                  </Box>
                </form>
              </Box>
              <Box className="pageViewFooter">
                <Footer />
              </Box>
            </Box>
          </Box>

          {/* Modal Popup Starts Here - Add New Card*/}
          <Modal
            className="commonModal"
            open={openAddCard}
            onClose={() => closepopUp()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="">
              {/* Modal Popup Starts Here - Add New Card*/}
              <Box className="commonModalBox">
                <Box className="commonModalBoxInner">
                  <Box id="modal-modal-title" className="commonModalHead">
                    <Grid container spacing={2}>
                      <Grid item xs={11} md={11} lg={11}>
                        <Typography className="modalTitle">
                          Payment Notice
                        </Typography>
                      </Grid>
                      <Grid item xs={1} md={1} lg={1}>
                        <span className="modalClose">
                          <CloseRoundedIcon onClick={() => closepopUp()} />
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box id="modal-modal-description" className="commonModalBody">
                    <Typography className="sectionTitle primaryColor">
                      {paymentName.replace("_", " ")} details changed, please
                      double check before you send amount.
                    </Typography>
                    <Box>
                      <Typography>
                        <b>1.</b> Please do not save the current UPI/BANK
                        account for next use! Be sure to confirm that the
                        UPI/BANK account you have obtained is the latest
                        account.
                      </Typography>
                      <Typography>
                        <b>2.</b> If you fail to comply with this agreement and
                        cause financial loses that cannot be recovered, tha
                        platform will not be responsible.
                      </Typography>
                      <Typography>
                        <b>3.</b> If you have any questions, please contact
                        customer service.
                      </Typography>
                    </Box>
                  </Box>
                  <Box id="modal-modal-footer" className="commonFooter">
                    <Stack spacing={2} direction="row">
                      {/* <Button variant="outlined" className="primaryLineBtn"><span>Cancel</span></Button> */}
                      <Button
                        variant="contained"
                        className="primaryFillBtn"
                        onClick={() => aggreeConditon()}
                      >
                        <span>Agree</span>
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Box>
              {/* Modal Popup Ends Here - Add New Card*/}
            </Box>
          </Modal>
          {alertConfig && alertConfig.isShow && (
            <AlertDialog
              alertConfig={alertConfig}
              callBack={alertConfig.callBack}
            />
          )}
          {/* Modal Popup Ends Here - Add New Card*/}
        </Box>
      )}
    </Formik>
  );
};
export default MyWalletRecharge;
