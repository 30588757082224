import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import { API_ERROR_STANDARD_MESSAGE } from "../../constants/DBConstants.contant";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { LoadingContext } from "../../context/loading.context";
import "../playGames/playGames.screen.style.css";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../context/toast.context";

type SubmitGameOptionsPageModel = {
  gameConfig?: IGamesConfigModel | null;
  isShow: boolean;
};

const PlayGames: FunctionComponent<PageProps> = ({ title }) => {
  const [gamesConfig, setGamesConfig] = useState<IGamesConfigModel[]>();
  const _gamesConfigService = new GamesConfigService({});
  const [openActiveGame, setOpenActiveGame] = React.useState<
    SubmitGameOptionsPageModel
  >({ isShow: false });
  const { setLoading } = useContext(LoadingContext);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const navigate = useNavigate();
  const { setToastConfig, setOpen } = useContext(ToastContext);

  useEffect(() => {
    initializeFunctions();

    async function initializeFunctions() {
      setLoading(true);
      await getGamesConfig();
    }
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  const getGamesConfig = async () => {
    try {
      const response: IApiResponse = await _gamesConfigService.getGamesConfig();
      setGamesConfig(response.response as IGamesConfigModel[]);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
    setLoading(false);
  };

  const handleOpenGame = (gameConf: IGamesConfigModel) => {
    navigate("/playLottery", { state: gameConf });
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  useEffect(() => {
    if (!openActiveGame.isShow && openActiveGame.gameConfig != undefined) {
      setOpenActiveGame({ ...openActiveGame, gameConfig: undefined });
    }
  }, [openActiveGame]);

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody">
            <Box>
              <Grid container spacing={2}>
                {gamesConfig &&
                  gamesConfig.map((item: IGamesConfigModel) => {
                    return (
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={4}
                        xl={3}
                        key={item.id}
                      >
                        <Box className="commonCard">
                          <Card>
                            <CardContent className="padB0">
                              <Box className="commonBgCard offerImage">
                                <img
                                  alt="A2zlotto - Offers"
                                  className="commonImage"
                                  src={require("../../assets/images/offers/" +
                                    item.imageName)}
                                />
                              </Box>
                              <Box>
                                <Typography className="sectionTitle textEclipse">
                                  {item.name}
                                </Typography>
                                <Typography className="normalText">
                                  {item.description}
                                </Typography>
                              </Box>
                            </CardContent>
                            <CardActions>
                              <Button
                                variant="contained"
                                onClick={() => handleOpenGame(item)}
                                className="primaryFillBtn width90"
                              >
                                <span>Play</span>
                              </Button>
                            </CardActions>
                          </Card>
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
        {alertConfig && alertConfig.isShow && (
          <AlertDialog
            alertConfig={alertConfig}
            callBack={alertConfig.callBack}
          />
        )}
      </Box>
    </Box>
  );
};
export default PlayGames;
