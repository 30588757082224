import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";

//Component Import
import Account from "../../../components/account/account.component";

//Form
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

//Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//Styles
import "../login/login.screen.style.css";
import { ILoginModel } from "../../../interface/Request/ILoginModel";
import { useDispatch, useSelector } from "react-redux";
import { authInitiate } from "../../../actions/auth.actions";
import { ToastContext } from "../../../context/toast.context";
import { LoadingContext } from "../../../context/loading.context";
import { ToastSeverity } from "../../../constants/toastSeverity.contants";
import { Formik, Form, getIn, FieldArray } from "formik";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import Link from "@mui/material/Link";
import { API_ERROR_STANDARD_MESSAGE } from "../../../constants/DBConstants.contant";

const Login: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const loginUser = (payload: ILoginModel) =>
    dispatch<any>(authInitiate(payload));
  const { message, success } = useSelector((state: any) => state.authReducer);
  const { setToastConfig } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);

  const InitialValues = {
    username: "",
    password: "",
  };

  useEffect(() => {
    document.title = title;
  }, []);

  //Navigation
  const navigate = useNavigate();

  //Password Show Hide
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const SignInSchema = yup.object().shape({
    username: yup.string().required("UserId/Mobile is required"),
    password: yup.string().required("Password is required"),
  });

  const _handleSignIn = async (values: ILoginModel) => {
    try {
      setLoading(true);
      const isValid = await SignInSchema.isValid(values);
      console.log(isValid);
      if (isValid) {
        loginUser(values);
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (message && !success) {
      setToastConfig(ToastSeverity.Error, message, true);
    }
  }, [message]);

  return (
    <Formik
      initialValues={{ ...InitialValues }}
      validationSchema={SignInSchema}
      onSubmit={(values, { setSubmitting }) => _handleSignIn(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="height100">
          <Box className="pageMain accountMain">
            <Box className="pageView">
              <Box className="accountBody height100">
                <Grid container spacing={2} className="height100">
                  <Grid item xs={12} md={12} lg={12}>
                    <Box className="accountBodyRight height100">
                      <Box className="commonCard">
                        <Card className="height100">
                          <CardContent>
                            <Typography className="accountTitle">
                              Login
                            </Typography>
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    id="username"
                                    label="UserId/Mobile"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.username}
                                  />
                                  {errors.username && touched.username && (
                                    <FormHelperText className="errorMessage">
                                      {errors.username}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-password">
                                      Password
                                    </InputLabel>
                                    <OutlinedInput
                                      type={showPassword ? "text" : "password"}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="Password"
                                      id="password"
                                      onChange={handleChange}
                                      value={values.password}
                                    />
                                    {errors.password && touched.password && (
                                      <FormHelperText className="errorMessage">
                                        {errors.password}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                          <CardActions>
                            <Grid container spacing={2}>
                              <Grid item xs={6} md={6} lg={6}>
                                <Box className="">
                                  <Typography component="a">
                                    <Link
                                      onClick={(event) => {
                                        event.preventDefault();
                                        navigate("/forgotPassword");
                                      }}
                                    >
                                      Forgot Password
                                    </Link>
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={6} md={6} lg={6}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  className="primaryFillBtn width100"
                                >
                                  <span>Submit</span>
                                </Button>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12}>
                                <Typography component="a">
                                  Don't Have Accout?{" "}
                                  <Link
                                    onClick={(event) => {
                                      event.preventDefault();
                                      navigate("/signup");
                                    }}
                                  >
                                    Signup
                                  </Link>
                                </Typography>
                                <Typography component="a"> here.</Typography>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
export default Login;
