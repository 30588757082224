import React, { FunctionComponent, useEffect, useState } from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Icons
import AirlineStopsRoundedIcon from "@mui/icons-material/AirlineStopsRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";

//Css
import "../exchangeRates/exchangeRates.screen.style.css";
import { getCountries } from "../../actions/lookup.actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ICountryModel } from "../../interface/BusinessModels/ICountryModel";
import axios from "axios";

import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import { Form, Formik } from "formik";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import LookupService from "../../services/lookup/lookup.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { CONVERT_CURRENCY } from "../../constants/EndPoints.constant";
import { ICurrencyConvertResponseModel } from "../../interface/Response/ICurrencyConvertResponseModel";

const ExchangeRates: FunctionComponent<PageProps> = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initializeCountries = () => dispatch<any>(getCountries());
  const { countries } = useSelector((state: any) => state.lookupReducer);
  const [convertResponse, setConvertResponse] = useState<{
    isShow: boolean;
    response: ICurrencyConvertResponseModel | null;
  }>({ isShow: false, response: null });
  const _lookupService = new LookupService({});

  useEffect(() => {
    document.title = title;
  }, []);

  const InitialValues = {
    from: "",
    to: "",
  };

  const convertCurrency = async (values: any) => {
    const convertResponse: IApiResponse = await _lookupService.convertCurrency(
      values
    );
    setConvertResponse({
      isShow: true,
      response: convertResponse.response as ICurrencyConvertResponseModel,
    });
  };

  const ConvertCurrencySchema = yup.object().shape({
    from: yup.string().required("Currency Code is required."),
    to: yup.string().required("Currency Code is required."),
  });
  return (
    <Formik
      initialValues={{ ...InitialValues }}
      validationSchema={ConvertCurrencySchema}
      onSubmit={(values, { setSubmitting }) => {
        convertCurrency(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="height100">
          <Box className="pageMain">
            <Box className="pageLeft">
              <LeftMenu />
            </Box>
            <Box className="pageRight">
              <Box className="pageHead">
                <TopNav title={title.split("|")[1].trim()} />
              </Box>
              <Box className="pageView">
                {/* <Box className="pageViewHead">
                        <Typography variant="h6">Exchange Rates</Typography>
                    </Box> */}
                <Box className="pageViewBody commonScroll">
                  <Box className="exchangeRates">
                    <Card>
                      <CardContent>
                        <Box className="saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={5} md={4} lg={5}>
                              <Box>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    From
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="from"
                                    label="From"
                                    onChange={(evt: SelectChangeEvent) => {
                                      setConvertResponse({
                                        isShow: false,
                                        response: null,
                                      });
                                      if (evt.target.value === "0") {
                                        values.from = "";
                                      } else {
                                        const country: ICountryModel[] = countries.filter(
                                          (x: ICountryModel) =>
                                            x.id.toString() ===
                                            evt.target.value.toString()
                                        );
                                        values.from = country[0].currencyCode;
                                      }
                                    }}
                                  >
                                    {countries &&
                                      countries.map(
                                        (country: ICountryModel) => (
                                          <MenuItem
                                            key={country.id}
                                            value={country.id.toString()}
                                          >
                                            {country.name}(
                                            {country.currencyCode})
                                          </MenuItem>
                                        )
                                      )}
                                  </Select>
                                  {errors.from && touched.from && (
                                    <FormHelperText className="errorMessage">
                                      {errors.from}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              md={2}
                              lg={2}
                              className="commonCenter"
                            >
                              <AirlineStopsRoundedIcon />
                            </Grid>
                            <Grid item xs={5} md={4} lg={5}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  To
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="to"
                                  label="From"
                                  onChange={(evt: SelectChangeEvent) => {
                                    setConvertResponse({
                                      isShow: false,
                                      response: null,
                                    });
                                    if (evt.target.value === "0") {
                                      values.from = "";
                                    } else {
                                      const country: ICountryModel[] = countries.filter(
                                        (x: ICountryModel) =>
                                          x.id.toString() ===
                                          evt.target.value.toString()
                                      );
                                      values.to = country[0].currencyCode;
                                    }
                                  }}
                                >
                                  {countries &&
                                    countries.map((country: ICountryModel) => (
                                      <MenuItem
                                        key={country.id}
                                        value={country.id.toString()}
                                      >
                                        {country.name}({country.currencyCode})
                                      </MenuItem>
                                    ))}
                                </Select>
                                {errors.to && touched.to && (
                                  <FormHelperText className="errorMessage">{errors.to}</FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Box>
                        {convertResponse.isShow && (
                          <Box className="commonBgCard">
                            <Box className="saperatorSpacing">
                              <Grid container spacing={2}>
                                <Grid item xs={5} md={5} lg={5}>
                                  <Typography>1 {values.from}</Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  md={2}
                                  lg={2}
                                  className="commonCenter"
                                >
                                  <span>
                                    <DragHandleRoundedIcon />
                                  </span>
                                </Grid>
                                <Grid item xs={5} md={5} lg={5}>
                                  <span>
                                    {convertResponse.response &&
                                      convertResponse.response
                                        .conversionRate}{" "}
                                    {values.to}
                                  </span>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        )}
                      </CardContent>
                      <CardActions>
                        <Button
                          type="submit"
                          variant="contained"
                          className="primaryFillBtn width100"
                          disabled={convertResponse.isShow}
                        >
                          <span>Submit</span>
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                </Box>
              </Box>
              <Box className="pageViewFooter">
                <Footer />
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
export default ExchangeRates;
