import { USER_LOGIN } from "../constants/EndPoints.constant";
import { ILoginModel } from "../interface/Request/ILoginModel";
import { IUserRegistration } from "../interface/Request/IUserRegistration";
import HttpHelperService from "../services/httpHelper.service";
import { useContext, createRef } from "react";
import {
  AUTH_REQUESTED,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_UNAUTHORIZED,
} from "../constants/Reducer.constant";
import axios from "axios";
import { Action, Dispatch } from "redux";
import { AnyAction } from "redux";
import { IApiResponse } from "../interface/Response/IApiResponse";
import ApplicationHelperService from "../services/ApplicationHelperService";
import { ISignInResponseModel } from "../interface/Response/ISignInResponseModel";

const _httpHelperService = new HttpHelperService({});
const _applicationHelperService = new ApplicationHelperService({});

export const authInitiate = (payload: ILoginModel) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    dispatch({
      type: AUTH_REQUESTED,
      payload: payload,
    });
    _httpHelperService
      .login(payload)
      .then(async (response: IApiResponse) => {
        if (response.isSuccess) {
          localStorage.setItem(
            "BetBhatLoggedInUserInfo",
            JSON.stringify(response.response)
          );
        }
        dispatch({
          type: response.isSuccess ? AUTH_SUCCESS : AUTH_ERROR,
          payload: response,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: AUTH_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error,
    });
  }
};

export const updateUserData = (payload: any) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    dispatch({
      type: AUTH_SUCCESS,
      payload: payload,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error,
    });
  }
};

export const logOut = () => (dispatch: Dispatch) => {
  localStorage.removeItem("BetBhatLoggedInUserInfo");
  dispatch({
    type: AUTH_LOGOUT,
    payload: null,
  });
};

export const sessionExpired = () => (dispatch: Dispatch) => {
  localStorage.removeItem("BetBhatLoggedInUserInfo");
  dispatch({
    type: AUTH_UNAUTHORIZED,
    payload: { message: "" },
  });
};
