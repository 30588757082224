import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

//Form
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

//Modal
import Modal from "@mui/material/Modal";

//Icons
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import {
  AGENT_CONTACT_TYPE,
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
} from "../../constants/DBConstants.contant";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { LoadingContext } from "../../context/loading.context";

//Import Css
import "../playGames/playGames.screen.style.css";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../context/toast.context";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteSweepRoundedIcon from "@mui/icons-material/DeleteSweepRounded";
import { Form, Formik } from "formik";
import { IApplicationAgentRegistrationModel } from "../../interface/BusinessModels/IApplicationAgentRegistrationModel";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import UserService from "../../services/user/user.service";
import { ICountryModel } from "../../interface/BusinessModels/ICountryModel";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../actions/lookup.actions";
import LookupService from "../../services/lookup/lookup.service";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Stack from "@mui/material/Stack";
import AgentUsers from "../../components/agentUsers/agentUsers.component";

type SubmitGameOptionsPageModel = {
  gameConfig?: IGamesConfigModel | null;
  isShow: boolean;
};

const ApplicationAgents: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const [openAddCard, setOpenAddCard] = React.useState<boolean>(false);
  const _gamesConfigService = new GamesConfigService({});
  const _lookupService = new LookupService({});
  const _userService = new UserService({});

  const { setLoading } = useContext(LoadingContext);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const navigate = useNavigate();

  //Modal Close
  const handleClose = () => setOpenAddCard(false);

  const { setToastConfig, setOpen } = useContext(ToastContext);
  const initializeCountries = () => dispatch<any>(getCountries());
  const { countries } = useSelector((state: any) => state.lookupReducer);
  const [countryId, setCountryId] = useState<number>(0);
  const [activeDeleteId, setActiveDeleteId] = useState<number>(0);
  const [agentPaymentStatuses, setAgentPaymentStatuses] = useState<
    ILookupModel[]
  >();

  const [agentUsers, setAgentUsers] = useState<
    IApplicationAgentRegistrationModel[]
  >();

  const InitialValues: IApplicationAgentRegistrationModel = {
    name: "",
    contactTypeId: "",
    phoneCode: "",
    phoneNumber: "",
    countryCode: "",
    country: "",
    state: "",
    countryId: 0,
  };

  const [applicationAgent, setApplicationAgent] = useState<
    IApplicationAgentRegistrationModel
  >(InitialValues);

  useEffect(() => {
    initializeCountries();
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    getAgentContactTypes();

    async function getAgentContactTypes() {
      setLoading(true);
      try {
        const contactTypes: IApiResponse = await _lookupService.getConfigs(
          AGENT_CONTACT_TYPE
        );
        const paymentStatuses: ILookupModel[] = contactTypes.response as ILookupModel[];
        setAgentPaymentStatuses(paymentStatuses);

        await getAgentUsers();
      } catch (error) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      setLoading(false);
    }
  }, []);

  const getAgentUsers = async () => {
    setLoading(true);
    try {
      const agentUsers: IApiResponse = await _userService.getAgentUsers();
      const agentUsersList: IApplicationAgentRegistrationModel[] = agentUsers.response as IApplicationAgentRegistrationModel[];
      setAgentUsers(agentUsersList);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
    setLoading(false);
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const AgentRegistrationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    contactTypeId: yup.string().required("Contact Type is required."),
    phoneCode: yup.string().required("Phone Code is required."),
    phoneNumber: yup.string().required("Phone Number is required."),
    country: yup.string().required("Country is required."),
    state: yup.string().required("State is required."),
  });

  const deleteApplicationAgent = async () => {
    try {
      var response: IApiResponse = await _userService.deleteAgentUser(
        activeDeleteId
      );
      if (response.isSuccess) {
        setAlertConfig({
          description: response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            getAgentUsers();
          },
        });
        setActiveDeleteId(0);
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
  };

  const saveApplicationAgent = async (
    values: IApplicationAgentRegistrationModel
  ) => {
    try {
      let responseData: IApiResponse;
      if (values.id && values.id > 0) {
        const response: IApiResponse = await _userService.updateAgentUser({
          ...values,
          phoneNumber: values.phoneNumber.toString(),
        });
        responseData = response;
      } else {
        const response: IApiResponse = await _userService.registerAgentUser({
          ...values,
          phoneNumber: values.phoneNumber.toString(),
        });
        responseData = response;
      }
      if (responseData.isSuccess) {
        setAlertConfig({
          description: responseData.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            getAgentUsers();
            setOpenAddCard(false);
          },
        });
      } else {
        setToastConfig(ToastSeverity.Error, responseData.message, true);
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (applicationAgent.id && applicationAgent.id > 0) {
      setOpenAddCard(true);
      setCountryId(applicationAgent.countryId);
    }
  }, [applicationAgent]);

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={9}></Grid>
                <Grid item xs={12} md={4} lg={3} className="boxRight">
                  <Button
                    onClick={() => setOpenAddCard(true)}
                    className="primaryColor"
                  >
                    <span>
                      <AddCircleOutlineRoundedIcon className="customIcon" />
                      Create Agent
                    </span>
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <AgentUsers />
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>

        {alertConfig && alertConfig.isShow && (
          <AlertDialog
            alertConfig={alertConfig}
            callBack={alertConfig.callBack}
          />
        )}
      </Box>
      {activeDeleteId > 0 && (
        <Modal
          className="commonModal"
          open={activeDeleteId > 0}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="commonModalBox">
            <Box className="commonModalBoxInner">
              <Box id="modal-modal-title" className="commonModalHead">
                <Grid container spacing={1}>
                  <Grid item xs={11} md={11} lg={11}>
                    <Typography className="modalTitle">Delete Agent</Typography>
                  </Grid>
                  <Grid item xs={1} md={1} lg={1}>
                    <span className="modalClose">
                      <CloseRoundedIcon onClick={() => setActiveDeleteId(0)} />
                    </span>
                  </Grid>
                </Grid>
              </Box>
              <Box id="modal-modal-description" className="commonModalBody">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography>
                      Are you sure you want to delete, this action cannot be
                      reverted?
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box id="modal-modal-footer" className="commonFooter">
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    className="primaryLineBtn"
                    onClick={() => setActiveDeleteId(0)}
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    variant="contained"
                    className="primaryFillBtn"
                    onClick={() => deleteApplicationAgent()}
                  >
                    <span>Delete</span>
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      <Modal
        className="commonModal"
        open={openAddCard}
        onClose={() => setOpenAddCard(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="commonModalBox">
          <Box className="commonModalBoxInner">
            <Box id="modal-modal-title" className="commonModalHead">
              <Grid container spacing={1}>
                <Grid item xs={11} md={11} lg={11}>
                  <Typography className="modalTitle">
                    Create/Edit Agent
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                  <span className="modalClose" onClick={handleClose}>
                    <CloseRoundedIcon />
                  </span>
                </Grid>
              </Grid>
            </Box>
            <Box id="modal-modal-description" className="commonModalBody">
              <Formik
                enableReinitialize
                initialValues={{ ...applicationAgent }}
                validationSchema={AgentRegistrationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  saveApplicationAgent(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} className="height100">
                    <Box className="">
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} lg={12}>
                            <TextField
                              id="name"
                              label="Name"
                              variant="outlined"
                              fullWidth
                              onChange={handleChange}
                              value={values.name}
                            />

                            {errors.name && touched.name && (
                              <FormHelperText className="errorMessage">
                                {errors.name}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} md={12} lg={4}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="outlined-country-dropdown-label">
                                Country
                              </InputLabel>
                              <Select
                                labelId="outlined-country-dropdown-label"
                                id="country-dropdown"
                                label="Country"
                                value={countryId.toString()}
                                onChange={(evt: SelectChangeEvent) => {
                                  if (evt.target.value === "0") {
                                    values.phoneCode = "";
                                    values.country = "";
                                  } else {
                                    const country: ICountryModel[] = countries.filter(
                                      (x: ICountryModel) =>
                                        x.id.toString() ===
                                        evt.target.value.toString()
                                    );
                                    values.phoneCode = country[0].phoneCode;
                                    values.country = country[0].name;
                                  }
                                  setCountryId(+evt.target.value);
                                }}
                              >
                                <MenuItem value={0}>Select</MenuItem>
                                {countries &&
                                  countries.map((country: ICountryModel) => (
                                    <MenuItem
                                      key={country.id}
                                      value={country.id.toString()}
                                    >
                                      {country.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {errors.countryCode && touched.countryCode && (
                                <FormHelperText className="errorMessage">
                                  {errors.countryCode}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} lg={2}>
                            <TextField
                              id="phoneCode"
                              label="Code"
                              variant="outlined"
                              fullWidth
                              disabled
                              value={values.phoneCode}
                            />
                            {errors.phoneCode && touched.phoneCode && (
                              <FormHelperText className="errorMessage">
                                {errors.phoneCode}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                            <TextField
                              type="number"
                              id="phoneNumber"
                              label="Phone Number"
                              variant="outlined"
                              fullWidth
                              onChange={handleChange}
                              value={values.phoneNumber.toString()}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <FormHelperText className="errorMessage">
                                {errors.phoneNumber}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="outlined-country-dropdown-label">
                                Contact Type
                              </InputLabel>
                              <Select
                                labelId="outlined-country-dropdown-label"
                                id="contactTypeId"
                                label="Contact Type"
                                value={
                                  values.contactTypeId == ""
                                    ? "0"
                                    : values.contactTypeId
                                }
                                onChange={(evt: SelectChangeEvent) => {
                                  if (evt.target.value === "0") {
                                    setFieldValue("contactTypeId", "");
                                  } else {
                                    setFieldValue(
                                      "contactTypeId",
                                      evt.target.value
                                    );
                                  }
                                }}
                              >
                                <MenuItem value={0}>Select</MenuItem>
                                {agentPaymentStatuses &&
                                  agentPaymentStatuses.map(
                                    (paymentStats: ILookupModel) => (
                                      <MenuItem
                                        key={paymentStats.id}
                                        value={paymentStats.id.toString()}
                                      >
                                        {paymentStats.value}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                              {errors.contactTypeId &&
                                touched.contactTypeId && (
                                  <FormHelperText className="errorMessage">
                                    {errors.contactTypeId}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                            <TextField
                              id="state"
                              label="State"
                              variant="outlined"
                              fullWidth
                              onChange={handleChange}
                              value={values.state}
                            />

                            {errors.state && touched.state && (
                              <FormHelperText className="errorMessage">
                                {errors.state}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}></Grid>
                        </Grid>
                      </Box>
                      <Button
                        type="submit"
                        variant="contained"
                        className="primaryFillBtn width100"
                      >
                        <span>Submit</span>
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default ApplicationAgents;
