import React, { useEffect } from 'react';

declare global {
  interface Window {
    googleTranslateElementInit: () => void;
    google: any;
  }
}

const GoogleTranslate: React.FC = () => {
  useEffect(() => {
    const addGoogleTranslateScript = () => {
      const googleTranslateScript = document.createElement('script');
      googleTranslateScript.src =
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      googleTranslateScript.type = 'text/javascript';
      googleTranslateScript.async = true;
      document.body.appendChild(googleTranslateScript);
    };

    const initGoogleTranslate = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          includedLanguages: 'en,th',
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: false,
        },
        'google_translate_element'
      );
    };

    window.googleTranslateElementInit = initGoogleTranslate;
    addGoogleTranslateScript();

    // Add custom CSS to hide unwanted elements
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      div[id^='goog-gt-'] {
        display: none !important;
      }
      .goog-te-banner-frame.skiptranslate {
        display: none !important;
      }
      body {
        top: 0px !important;
      }
      .goog-logo-link {
        display: none !important;
      }
      .goog-te-gadget-icon {
        display: none !important;
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      const googleTranslateScript = document.querySelector(
        'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
      );
      if (googleTranslateScript && googleTranslateScript.parentNode) {
        googleTranslateScript.parentNode.removeChild(googleTranslateScript);
      }
    };
  }, []);

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
