import React, {
  FunctionComponent
} from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";


import PageProps from "../../models/PageProps.interface";
import { Typography } from "@mui/material";




const PageConstruction: FunctionComponent<PageProps> = ({ title }) => {

  return (
    <Box className="pageMain">
      <Box className="pageConstructionPage">
                    <img
                      alt="A2zlotto - Page Construction"
                      className=""
                      src={require("../../assets/images/pageConstruction/construction.png")}
                    />
                    
                  </Box>
    </Box>
  );
};
export default PageConstruction;
