import React, { FunctionComponent } from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

//Form
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

//Modal
import Modal from "@mui/material/Modal";

//Icons Import
import DeleteIcon from "@mui/icons-material/Delete";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Import Css
import "../manageMedia/manageMedia.screen.style.css";

const ManageMedia: FunctionComponent<PageProps> = ({ title }) => {
  const [openDeleteCard, setOpenDeleteCard] = React.useState(false);
  const handleOpenDeleteCard = () => setOpenDeleteCard(true);
  const handleCloseDeleteCard = () => setOpenDeleteCard(false);

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          {/* <Box className="pageViewHead">
                        <Typography variant="h6">My Wallet</Typography>
                    </Box> */}
          <Box className="pageViewBody commonScroll">
            <Box>
              <Box className="commonBgCard saperatorSpacing">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={8}>
                    <Typography className="sectionTitle">
                      All Media <span>(53)</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={10} md={4} lg={3} className="verticalCenter">
                    <FormControl fullWidth>
                      <Button
                        variant="contained"
                        className="primaryFillBtn floatR"
                      >
                        <span>Submit</span>
                      </Button>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} md={2} lg={1} className="verticalCenter">
                    <Button
                      variant="contained"
                      className="primaryFillBtn floatR"
                    >
                      <AddCircleIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4} lg={4}></Grid>
                <Grid item xs={6} md={2} lg={2}></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className="commonCard textDecorationNone">
                    <Card className="mangageMediaCard">
                      <CardContent>
                        <Box className="mediaCard">
                          <img
                            alt="A2zlotto - Manage Media"
                            className="commonImage"
                            src={require("../../assets/images/manageMedia/GloBuilding2.jpg")}
                          />
                        </Box>
                      </CardContent>
                      <CardActions className="actionInfo">
                        <Box className="commonBgCard saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={8}>
                              <Typography className="cardTitle">
                                About Glo
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Download Image">
                                  <IconButton>
                                    <DownloadForOfflineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Delete Image">
                                  <IconButton onClick={handleOpenDeleteCard}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className="commonCard textDecorationNone">
                    <Card className="mangageMediaCard">
                      <CardContent>
                        <Box className="mediaCard">
                          <img
                            alt="A2zlotto - Manage Media"
                            className="commonImage"
                            src={require("../../assets/images/manageMedia/GloBuilding2.jpg")}
                          />
                        </Box>
                      </CardContent>
                      <CardActions className="actionInfo">
                        <Box className="commonBgCard saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={8}>
                              <Typography className="cardTitle">
                                About Glo
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Download Image">
                                  <IconButton>
                                    <DownloadForOfflineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Delete Image">
                                  <IconButton>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className="commonCard textDecorationNone">
                    <Card className="mangageMediaCard">
                      <CardContent>
                        <Box className="mediaCard">
                          <img
                            alt="A2zlotto - Manage Media"
                            className="commonImage"
                            src={require("../../assets/images/manageMedia/GloBuilding2.jpg")}
                          />
                        </Box>
                      </CardContent>
                      <CardActions className="actionInfo">
                        <Box className="commonBgCard saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={8}>
                              <Typography className="cardTitle">
                                About Glo
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Download Image">
                                  <IconButton>
                                    <DownloadForOfflineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Delete Image">
                                  <IconButton>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className="commonCard textDecorationNone">
                    <Card className="mangageMediaCard">
                      <CardContent>
                        <Box className="mediaCard">
                          <img
                            alt="A2zlotto - Manage Media"
                            className="commonImage"
                            src={require("../../assets/images/manageMedia/GloBuilding2.jpg")}
                          />
                        </Box>
                      </CardContent>
                      <CardActions className="actionInfo">
                        <Box className="commonBgCard saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={8}>
                              <Typography className="cardTitle">
                                About Glo
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Download Image">
                                  <IconButton>
                                    <DownloadForOfflineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Delete Image">
                                  <IconButton>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className="commonCard textDecorationNone">
                    <Card className="mangageMediaCard">
                      <CardContent>
                        <Box className="mediaCard">
                          <img
                            alt="A2zlotto - Manage Media"
                            className="commonImage"
                            src={require("../../assets/images/manageMedia/GloBuilding2.jpg")}
                          />
                        </Box>
                      </CardContent>
                      <CardActions className="actionInfo">
                        <Box className="commonBgCard saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={8}>
                              <Typography className="cardTitle">
                                About Glo
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Download Image">
                                  <IconButton>
                                    <DownloadForOfflineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Delete Image">
                                  <IconButton>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className="commonCard textDecorationNone">
                    <Card className="mangageMediaCard">
                      <CardContent>
                        <Box className="mediaCard">
                          <img
                            alt="A2zlotto - Manage Media"
                            className="commonImage"
                            src={require("../../assets/images/manageMedia/GloBuilding2.jpg")}
                          />
                        </Box>
                      </CardContent>
                      <CardActions className="actionInfo">
                        <Box className="commonBgCard saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={8}>
                              <Typography className="cardTitle">
                                About Glo
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Download Image">
                                  <IconButton>
                                    <DownloadForOfflineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                              <Box className="">
                                <Tooltip title="Delete Image">
                                  <IconButton>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Modal Popup Starts Here - Delete Card*/}
            <Modal
              className="commonModal"
              open={openDeleteCard}
              onClose={handleCloseDeleteCard}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="commonModalBox">
                <Box className="commonModalBoxInner">
                  <Box id="modal-modal-title" className="commonModalHead">
                    <Grid container spacing={2}>
                      <Grid item xs={11} md={11} lg={11}>
                        <Typography className="modalTitle">
                          Delete Bank Card
                        </Typography>
                      </Grid>
                      <Grid item xs={1} md={1} lg={1}>
                        <span className="modalClose">
                          <CloseRoundedIcon onClick={handleCloseDeleteCard} />
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box id="modal-modal-description" className="commonModalBody">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography>
                          Are you sure you want to delete?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box id="modal-modal-footer" className="commonFooter">
                    <Stack spacing={2} direction="row">
                      <Button variant="outlined" className="primaryLineBtn">
                        <span>Cancel</span>
                      </Button>
                      <Button variant="contained" className="primaryFillBtn">
                        <span>Delete</span>
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Modal>
            {/* Modal Popup Ends Here - Delete Card*/}
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ManageMedia;
