export enum RoleType {
  SuperAdmin = 1,
  Admin = 2,
  User = 3,
  Agent = 33,
}

export const ROLE_CONFIG: string = "Role";
export const WITHDRAWALACCOUNTTYPE_CONFIG: string = "WithdrawalAccountType";
export const GAMESTATUS_CONFIG: string = "GameStatus";
export const RECHARGESTATUS_CONFIG: string = "RechargeStatus";
export const AGENT_CONTACT_TYPE: string = "AgentContactType";
export const ROLES_TYPE: string = "Role";
export const OFFER_TYPE: string = "OfferType";
export const TRANSACTION_TYPE_CONFIG: string = "TransactionType";

export enum WithdrawalAccountType {
  UPI = 4,
  BankAccount = 5,
}

export enum ConfigGames {
  THAILANDLOTTERY = 1,
  BANGKOKWEEKLYLOTTERY = 2,
}

export enum OfferType {
  WELCOME_BONUS = 14,
  RECHARGE_BONUS = 15,
  REFERRAL_BONUS = 16,
}

export enum GameResultType {
  Pending = 30,
  Delayed = 31,
  Declared = 32,
}

export enum RechargeStatus {
  Pending = 17,
  Approved = 18,
  Declined = 19,
}

export const DEFAULT_PAGESIZE = 50;

export const PAYMENT_SETTINGS: string = "PAYMENT_SETTINGS";
export const APPLICATION_SETTINGS: string = "APPLICATION_SETTINGS";
export const SOCIAL_CONNECTION_SETTINGS: string = "SOCIAL_CONNECTION_SETTINGS";
export const PERMISSION_SETTINGS: string = "PERMISSION_SETTINGS";
export const GAME_PERMISSION: string = "GAME_PERMISSION";
export const GAME_GENERAL_SETTINGS: string = "GAME_GENERAL_SETTINGS";
export const GAME_DISCOUNTS: string = "GAME_DISCOUNTS";
export const GAME_SPECIAL_DISCOUNTS: string = "GAME_SPECIAL_DISCOUNTS";
export const GAME_WINNINGS: string = "GAME_WINNINGS";
export const LAST_DAY_DISCOUNTS: string = "LAST_DAY_DISCOUNTS";
export const LOTTERY_HISTORY: string = "LOTTERY_HISTORY";
export const BANK_ACCOUNT_NUMBER: string = "BANK_ACCOUNT_NUMBER";

export const RECHARGE: string = "RECHARGE";
export const DEDUCT: string = "DEDUCT";

export const API_ERROR_STANDARD_MESSAGE: string =
  "We are facing technical isses. Please try again later.";

export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const DEFAULT_ALERT_CONFIG = {
  isShow: false,
  callBack: () => undefined,
};

export enum TransactionType {
  GamePlay = 22,
}
