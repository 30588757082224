import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/joy";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

//Css Import
import "../playGamesLottery/playGamesLottery.screen.style.css";

import { useLocation, useNavigate } from "react-router-dom";
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import PageProps from "../../models/PageProps.interface";
import * as yup from "yup";
import {
  Formik,
  Form,
  getIn,
  FieldArray,
  useFormikContext,
  FormikProps,
} from "formik";
import { Divider } from "@mui/joy";
import { IPlayGameRequestModel } from "../../interface/Request/IPlayGameRequestModel";
import PlayGameService from "../../services/playGame/playGame.service";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { ToastContext } from "../../context/toast.context";

//Icons
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

//Import Css
import { IGameOptionsModel } from "../../interface/BusinessModels/IGameOptionsModel";
import LookupService from "../../services/lookup/lookup.service";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  GAME_GENERAL_SETTINGS,
  GAME_WINNINGS,
} from "../../constants/DBConstants.contant";
import Footer from "../../components/footer/footer.component";
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingContext } from "../../context/loading.context";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import "../lotteryGameSummary/lotteryGameSummary.screen.style.css";
import { IPlayedNumberModel } from "../../interface/BusinessModels/IPlayedNumberModel";
import { getWalletBalance } from "../../actions/lookup.actions";
import { useDispatch } from "react-redux";

type IPredictions = {
  id: number;
  number: string;
  straight: number;
  rumble: number;
};

type ILotterySubmission = {
  lotteryInput: IPredictions[];
};

type IAccountSummary = {
  playingAmount: number;
  discount: number;
  payableAmount: number;
};

const PlayGamesLottery: FunctionComponent<PageProps> = ({ title }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const INITIAL_RESET_MODAL: any = {
    isShow: false,
    selectGameOption: null,
  };
  const dispatch = useDispatch();
  const getUserWalletBalance = () => dispatch<any>(getWalletBalance());
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [gameOptions, setGameOptions] = useState<IGameOptionsModel[]>();
  const [showResetModel, setShowResetModel] = useState<any>({
    ...INITIAL_RESET_MODAL,
  });
  const [gamesConfigModel, setGamesConfigModel] = useState<IGamesConfigModel>(
    location.state as IGamesConfigModel
  );
  const [gameRules, setGameRules] = useState<ILookupModel[]>();
  const [gameDiscount, setGameDiscount] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<IGameOptionsModel>();
  const ACCOUNT_SUMMARY_INITIALS: IAccountSummary = {
    playingAmount: 0,
    discount: 0,
    payableAmount: 0,
  };
  const [accountSummary, setAccountSummary] = useState<IAccountSummary>({
    ...ACCOUNT_SUMMARY_INITIALS,
  });
  const [validationLength, setValidationLength] = useState<number>(0);
  const [minAmountToPlay, setMinAmountToPlay] = useState<number>(0);
  const _gamesConfigService = new GamesConfigService({});
  const _playGameService = new PlayGameService({});
  const _lookupService = new LookupService({});
  const { setLoading } = useContext(LoadingContext);

  const [currentValues, setCurrentValues] = useState<any>();
  const formik = useFormikContext();

  // const InitialAlertConfig: IAlertDialogConfig = {
  //   isShow: true,
  //   callBack: () => undefined,
  // };

  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>(
    DEFAULT_ALERT_CONFIG
  );

  const DummyIntialValue: IPredictions = {
    id: Math.random(),
    number: "",
    straight: 0,
    rumble: 0,
  };

  const InitialValues: ILotterySubmission = {
    lotteryInput: [
      DummyIntialValue,
      DummyIntialValue,
      DummyIntialValue,
      DummyIntialValue,
      // DummyIntialValue,
      // DummyIntialValue,
      // DummyIntialValue,
      // DummyIntialValue,
      // DummyIntialValue,
      // DummyIntialValue,
    ],
  };

  const [intialValues, setIntialValues] = useState<ILotterySubmission>(
    InitialValues
  );

  useEffect(() => {
    console.log("Params: ", location.state);
    initializeFunctions();

    async function initializeFunctions() {
      await getGamesSettings();
    }
  }, []);

  const validationSchema = yup.object().shape({
    lotteryInput: yup
      .array()
      .min(1, "Atleast one element required")
      .of(
        yup.object().shape(
          {
            number: yup
              .number()
              .nullable()
              .transform((curr, orig) => (orig === 0 ? null : curr))
              .min(
                validationLength,
                `Min numbers should be ${validationLength}`
              )
              .test({
                name: "minimum-length-check",
                message: `Enter Min of ${validationLength} numbers`,
                test: (value, ctx) => {
                  const { from } = ctx;
                  const objectValues = from as any;
                  const values = objectValues[0].value;
                  if (values.number) {
                    return values.number.length === validationLength;
                  } else {
                    return true;
                  }
                },
              })
              .test({
                name: "rumble-straight-check",
                message: "Enter Amount",
                test: (value, ctx) => {
                  const { from } = ctx;
                  const objectValues = from as any;
                  const values = objectValues[0].value;
                  if (values.number) {
                    return values.rumble !== 0 || values.straight !== 0;
                  } else {
                    return true;
                  }
                },
              }),
            straight: yup
              .number()
              .nullable()
              .transform((curr, orig) => (orig === 0 ? null : curr))
              .min(minAmountToPlay, `Min amount should be ${minAmountToPlay}`)
              .max(99999, "Should be maximum of 5 digits")
              .when(["rumble", "number"], (rumble, schema) => {
                if (!rumble[0] && rumble[1]) {
                  // return schema.required(
                  //   "Must enter value for either straight or rumble"
                  // );
                  return schema.required("");
                } else {
                  return schema;
                }
              }),
            rumble: yup
              .number()
              .nullable()
              .transform((curr, orig) => (orig === 0 ? null : curr))
              .min(minAmountToPlay, `Min amount should be ${minAmountToPlay}`)
              .max(99999, "Should be maximum of 5 digits")
              .when(["straight", "number"], (straight, schema) => {
                if (!straight[0] && straight[1]) {
                  // return schema.required(
                  //   "Must enter value for either straight or rumble"
                  // );
                  return schema.required("");
                } else {
                  return schema;
                }
              }),
          },
          [["straight", "rumble"]]
        )
      ),
  });

  const getGamesSettings = async () => {
    try {
      setLoading(true);
      const configResponse: IApiResponse = await _gamesConfigService.getGameOptions(
        gamesConfigModel.gameId
      );
      const gameOption: IGameOptionsModel[] = configResponse.response as IGameOptionsModel[];
      setSelectedOption(gameOption[0]);

      const gameGeneralSettings: IApiResponse = await _lookupService.getGameSettings(
        GAME_GENERAL_SETTINGS,
        gamesConfigModel.gameId
      );

      const minamount = gameGeneralSettings.response.filter(
        (x: ILookupModel) => x.key === "MIN_AMOUNT_PLAY"
      )[0].value;
      if (minamount !== "") {
        setMinAmountToPlay(minamount);
      }

      setGameOptions(gameOption);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedOption && selectedOption.id > 0) {
      getDiscountPercentage(selectedOption.id);
      selectedOption.option.includes("1st")
        ? setValidationLength(6)
        : selectedOption.option.includes("2")
        ? setValidationLength(2)
        : selectedOption.option.includes("1")
        ? setValidationLength(1)
        : setValidationLength(3);

      _gamesConfigService
        .getGameRules(gamesConfigModel.gameId, selectedOption.id)
        .then((rulesResponse: IApiResponse) => {
          let rules: ILookupModel[] = [];
          rules.push((rulesResponse.response as ILookupModel[])[0]);
          rules.push((rulesResponse.response as ILookupModel[])[2]);
          // setGameRules(rulesResponse.response as ILookupModel[]);
          setGameRules(rules);
        })
        .catch((err) => {});
    }
  }, [selectedOption]);

  const getDiscountPercentage = async (gameOptionId: number) => {
    try {
      setLoading(true);
      const configResponse: IApiResponse = await _gamesConfigService.getGameDiscount(
        gameOptionId
      );
      const gameDisc: ILookupModel = configResponse.response as ILookupModel;
      setGameDiscount(+gameDisc.value);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
    setLoading(false);
  };

  const percentage = (total: number) => {
    return ((gameDiscount / 100) * total).toFixed(2);
  };

  const debug = false;

  const handleFormReset = (selected: IGameOptionsModel) => {
    setAlertConfig(DEFAULT_ALERT_CONFIG);
    setAccountSummary({ ...ACCOUNT_SUMMARY_INITIALS });
    setIntialValues({ ...InitialValues });
    handleValidation({ ...InitialValues });
    setSelectedOption(selected);
  };

  const handleValidation = (values: ILotterySubmission) => {
    const matchedValues = values.lotteryInput.filter((x) => x.number !== "");
    if (matchedValues.length > 0) {
      const straightSum = matchedValues.reduce(
        (n, { straight }) => n + +straight,
        0
      );
      const rumbleSum = matchedValues.reduce((n, { rumble }) => n + +rumble, 0);
      const total = straightSum + rumbleSum;
      const discountedAmount = +percentage(total);
      setAccountSummary({
        playingAmount: total,
        discount: discountedAmount,
        payableAmount: total - discountedAmount,
      });
    } else {
      setAccountSummary({ ...ACCOUNT_SUMMARY_INITIALS });
    }
  };

  useEffect(() => {
    console.log(currentValues);
  }, [currentValues]);

  const submitLottery = async (values: IPredictions[]) => {
    setLoading(true);
    const request: IPlayGameRequestModel = {
      gameId: gamesConfigModel.gameId,
      gameHistoryId: gamesConfigModel.id,
      gameOption: selectedOption ? selectedOption.id : 0,
      playedAmount: accountSummary.playingAmount,
      discountApplied: gameDiscount,
      discountAmount: accountSummary.discount,
      paidAmount: accountSummary.payableAmount,
      numbers: values,
    };
    var response: IApiResponse = await _playGameService.submitGame(request);
    setLoading(false);
    if (response.isSuccess) {
      getUserWalletBalance();
      setAlertConfig({
        data: response.response.lotteryPlayed,
        description: response.message,
        toastSeverity: ToastSeverity.Success,
        isShow: true,
        callBack: () => {
          setAlertConfig(DEFAULT_ALERT_CONFIG);
        },
      });
    } else {
      setToastConfig(ToastSeverity.Error, response.message, true);
    }
  };

  const formRef = useRef<FormikProps<ILotterySubmission>>(null);

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={gamesConfigModel.name} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody">
            <Box className="commonModalBox commonExtraModalBox">
              <Box className="commonModalBoxInner">
                <Box
                  id="modal-modal-description"
                  className="commonModalBody responsivecommonModalBody"
                >
                  <Box className="saperatorSpacing">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box className="commonBgCard">
                          <Typography className="sectionTitle whiteColor">
                            {gamesConfigModel.announcement}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="saperatorSpacing">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl fullWidth>
                          <InputLabel id="gameType">Game Type</InputLabel>
                          <Select
                            labelId="gameType"
                            id="gameType"
                            label="gameType"
                            value={
                              selectedOption ? selectedOption.id.toString() : ""
                            }
                            onChange={(evt: SelectChangeEvent) => {
                              const selected =
                                gameOptions &&
                                gameOptions.filter(
                                  (x: IGameOptionsModel) =>
                                    x.id === +evt.target.value
                                );
                              if (selected) {
                                handleFormReset(selected[0]);
                              }
                            }}
                          >
                            {gameOptions &&
                              gameOptions.map((option: IGameOptionsModel) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id.toString()}
                                >
                                  {option.option}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="saperatorSpacing">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={8} lg={8}>
                        <Box className="commonBgCard commonBgColorCard noteSummaryCard">
                          <Typography className="sectionTitle">
                            Instructions
                          </Typography>
                          {gameRules &&
                            gameRules.map((rule: ILookupModel) => (
                              <div
                                className="bulletPoint"
                                key={rule.id}
                                dangerouslySetInnerHTML={{
                                  __html: rule.value.replace(
                                    "{{TotalPlayingAmountDiscount}}",
                                    accountSummary.discount.toString()
                                  ),
                                }}
                              ></div>
                            ))}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Box className="commonBgCard commonBgColorCard noteSummaryCard">
                          <Typography className="sectionTitle">
                            Amount Summary
                          </Typography>
                          <Box>
                            <Box className="commonLabelValue">
                              <Typography className="commonLabel">
                                Playing Amount :
                              </Typography>
                              <Typography className="commonValue">
                                {accountSummary.playingAmount}
                              </Typography>
                            </Box>
                            <Box className="commonLabelValue">
                              <Typography className="commonLabel">
                                Discount<span>({gameDiscount}%)</span> :
                              </Typography>
                              <Typography className="commonValue">
                                {accountSummary.discount}
                              </Typography>
                            </Box>
                            <Box className="commonLabelValue">
                              <Typography className="commonLabel">
                                Total Payble Amount :
                              </Typography>
                              <Typography className="commonValue">
                                {accountSummary.payableAmount}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Formik
                    innerRef={formRef}
                    enableReinitialize
                    initialValues={intialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      submitLottery(values.lotteryInput);
                      console.log("onSubmit", JSON.stringify(values, null, 2));
                    }}
                    // onReset={handleFormReset}
                    validate={handleValidation}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleReset,
                      isValid,
                      setFieldValue,
                      setErrors,
                    }) => (
                      <Form noValidate autoComplete="off">
                        <FieldArray name="lotteryInput">
                          {({ push, remove }) => (
                            <div>
                              {values.lotteryInput.map((p, index) => {
                                const number = `lotteryInput[${index}].number`;
                                const touchedNumber = getIn(touched, number);
                                const errorNumber = getIn(errors, number);

                                const straight = `lotteryInput[${index}].straight`;
                                const touchedStraight = getIn(
                                  touched,
                                  straight
                                );
                                const errorStraight = getIn(errors, straight);

                                const rumble = `lotteryInput[${index}].rumble`;
                                const touchedRumble = getIn(touched, rumble);
                                const errorRumble = getIn(errors, rumble);

                                return (
                                  <div key={index}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={4} md={4} lg={4}>
                                        <TextField
                                          type="number"
                                          margin="normal"
                                          variant="outlined"
                                          label="Number"
                                          name={number}
                                          fullWidth
                                          value={p.number}
                                          required
                                          helperText={
                                            touchedNumber && errorNumber
                                              ? errorNumber
                                              : ""
                                          }
                                          error={Boolean(
                                            touchedNumber && errorNumber
                                          )}
                                          onChange={(event: any) => {
                                            if (
                                              event.target.value.length <=
                                              validationLength
                                            ) {
                                              setFieldValue(
                                                `lotteryInput[${index}].number`,
                                                event.target.value
                                              );
                                              if (
                                                event.target.value.length ===
                                                  validationLength &&
                                                values.lotteryInput.length -
                                                  1 ===
                                                  index
                                              ) {
                                                push({
                                                  id: Math.random(),
                                                  number: "",
                                                  straight: 0,
                                                  rumble: 0,
                                                });
                                              }
                                            }
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </Grid>
                                      <Grid item xs={4} md={4} lg={4}>
                                        <TextField
                                          type="number"
                                          margin="normal"
                                          variant="outlined"
                                          label="Direct"
                                          name={straight}
                                          fullWidth
                                          value={
                                            p.straight == 0 ? "" : p.straight
                                          }
                                          helperText={
                                            touchedStraight && errorStraight
                                              ? errorStraight
                                              : ""
                                          }
                                          error={Boolean(
                                            touchedStraight && errorStraight
                                          )}
                                          onChange={(event: any) => {
                                            if (
                                              event.target.value.length <= 5
                                            ) {
                                              setFieldValue(
                                                `lotteryInput[${index}].straight`,
                                                event.target.value
                                              );
                                            }
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </Grid>
                                      {selectedOption &&
                                        selectedOption.isRumble && (
                                          <Grid item xs={4} md={4} lg={4}>
                                            <TextField
                                              type="number"
                                              margin="normal"
                                              variant="outlined"
                                              label="Rumble"
                                              name={rumble}
                                              inputProps={{ maxLength: 5 }}
                                              fullWidth
                                              value={
                                                p.rumble == 0 ? "" : p.rumble
                                              }
                                              helperText={
                                                touchedRumble && errorRumble
                                                  ? errorRumble
                                                  : ""
                                              }
                                              error={Boolean(
                                                touchedRumble && errorRumble
                                              )}
                                              onChange={(event: any) => {
                                                if (
                                                  event.target.value.length <= 5
                                                ) {
                                                  setFieldValue(
                                                    `lotteryInput[${index}].rumble`,
                                                    event.target.value
                                                  );
                                                }
                                              }}
                                              onBlur={handleBlur}
                                            />
                                          </Grid>
                                        )}

                                      {/* <Grid
                                        item
                                        xs={3}
                                        md={1}
                                        lg={1}
                                        className="removeRow"
                                      >
                                        <Button
                                          className="removeRowBtn"
                                          onClick={() => remove(index)}
                                        >
                                          <span>
                                            <CancelRoundedIcon className="primaryColorAction" />
                                          </span>
                                        </Button>
                                      </Grid> */}
                                    </Grid>
                                  </div>
                                );
                              })}
                              {/* <Button
                                type="button"
                                variant="outlined"
                                onClick={() =>
                                  push({
                                    id: Math.random(),
                                    number: 0,
                                    straight: 0,
                                    rumble: 0,
                                  })
                                }
                              >
                                <span>
                                  <PlaylistAddRoundedIcon className="customIcon" />
                                  Add Row
                                </span>
                              </Button> */}
                            </div>
                          )}
                        </FieldArray>
                        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                        <Stack
                          spacing={2}
                          direction="row"
                          className="boxRight"
                          sx={{ marginRight: 20 }}
                        >
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className="primaryFillBtn "
                            // disabled={!isValid || values.people.length === 0}
                          >
                            <span>submit</span>
                          </Button>
                          <Button
                            variant="outlined"
                            className="primaryLineBtn"
                            onClick={() => {
                              if (selectedOption) {
                                handleFormReset(selectedOption);
                              }
                            }}
                          >
                            <span className="primaryColorAction">Reset</span>
                          </Button>
                        </Stack>

                        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                        {debug && (
                          <>
                            <pre style={{ textAlign: "left" }}>
                              <strong>Values</strong>
                              <br />
                              {JSON.stringify(values, null, 2)}
                            </pre>
                            <pre style={{ textAlign: "left" }}>
                              <strong>Errors</strong>
                              <br />
                              {JSON.stringify(errors, null, 2)}
                            </pre>
                          </>
                        )}
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
        {alertConfig && alertConfig.isShow && (
          <Dialog
            open={alertConfig.isShow}
            className="alertModel"
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setAlertConfig(DEFAULT_ALERT_CONFIG)}
            aria-describedby="alert-dialog-slide-description"
          >
            <Box className="alertIcon">
              <CheckCircleOutlineRoundedIcon className="successColor" />
            </Box>
            {alertConfig.title && (
              <DialogTitle>{alertConfig.title}</DialogTitle>
            )}
            <DialogContent className="alertDialogDescription">
              <DialogContentText
                id="alert-dialog-slide-description"
                className="content"
              >
                {alertConfig.description}
                <Card>
                  <CardContent className="gameSucess">
                    <Box className="commonBgCard saperatorSpacing">
                      <Grid container spacing={2}>
                        <Grid item xs={10} md={10} lg={10}>
                          <Typography className="cardTitle">
                            {alertConfig.data.ticketNumber.toUpperCase()}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2} className="boxRight">
                          <Box className="">
                            <img
                              alt="A2zlotto - Flag"
                              className=""
                              src={require("../../assets/images/lotteryHistory/thailand.png")}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">Game</Typography>
                        <Typography className="commonValue">
                          {alertConfig.data.gameType}
                        </Typography>
                      </Box>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Game Type
                        </Typography>
                        <Typography className="commonValue">
                          {alertConfig.data.gameOption}
                        </Typography>
                      </Box>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Game Result
                        </Typography>
                        <Typography className="commonValue">
                          {alertConfig.data.status}
                        </Typography>
                      </Box>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Game Draw Date
                        </Typography>
                        <Typography className="commonValue">
                          {alertConfig.data.gameDrawDate}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        <Typography className="sectionTitle saperatorBorder">
                          Game Result
                        </Typography>
                      </Box>
                      <Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Total Amount(Played)
                          </Typography>
                          <Typography className="commonValue">
                            &nbsp;{alertConfig.data.playedAmount}
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Total Amount(Paid)
                          </Typography>
                          <Typography className="commonValue">
                            &nbsp;{alertConfig.data.paidAmount}
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Discount Amount
                          </Typography>
                          <Typography className="commonValue">
                            &nbsp;{alertConfig.data.discountAmount}
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Discount Applied
                          </Typography>
                          <Typography className="commonValue">
                            {alertConfig.data.discountApplied}&nbsp;%
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Agent Commission
                          </Typography>
                          <Typography className="commonValue">
                            &nbsp;0
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Final Winning Amount
                          </Typography>
                          <Typography className="commonValue">
                            &nbsp;0
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        <Typography className="sectionTitle saperatorBorder">
                          Numbers Played
                        </Typography>
                      </Box>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} lg={12}>
                            <TableContainer
                              component={Paper}
                              className="numbersPlayedTable"
                            >
                              <Table
                                aria-label="Game Result Table"
                                className="commonBasicTable"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell component="th">Number</TableCell>
                                    <TableCell component="th">
                                      Straight
                                    </TableCell>
                                    <TableCell component="th">Rumble</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {alertConfig.data.playedNumbers.map(
                                    (row: IPlayedNumberModel) => (
                                      <TableRow
                                        key={row.number}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell align="center">
                                          {row.number}
                                        </TableCell>
                                        <TableCell align="center">
                                          {row.straight}
                                        </TableCell>
                                        <TableCell align="center">
                                          {row.rumble}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="alertFooter">
              <Button
                variant="outlined"
                onClick={() => {
                  setAlertConfig(DEFAULT_ALERT_CONFIG);
                  setAccountSummary({ ...ACCOUNT_SUMMARY_INITIALS });
                  setIntialValues({ ...InitialValues });
                  handleValidation({ ...InitialValues });
                  // window.location.reload();
                }}
                className="alertAction"
              >
                <span>
                  {alertConfig.buttonTitle ? alertConfig.buttonTitle : "Okay!"}
                </span>
              </Button>
            </DialogActions>
          </Dialog>
          // <AlertDialog
          //   alertConfig={alertConfig}
          //   callBack={alertConfig.callBack}
          // />
        )}
        {/* <Dialog
          open={showResetModel.isShow}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Reset the form?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to reset the form, all the captured values will
              be deleted and cannnot be reset.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowResetModel({ ...INITIAL_RESET_MODAL })}
            >
              <span className="primaryColorAction">Cancel</span>
            </Button>
            <Button
              onClick={() => {
                handleFormReset();
                setSelectedOption(showResetModel.selectGameOption);
                setTimeout(() => {
                  setShowResetModel(false);
                }, 1000);
              }}
              autoFocus
            >
              <span>Confirm</span>
            </Button>
          </DialogActions>
        </Dialog> */}
      </Box>
    </Box>
  );
};
export default PlayGamesLottery;
