import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../../models/PageProps.interface";

//Gadgets Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

//Component Import
import Account from "../../../components/account/account.component";

//Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockResetRoundedIcon from "@mui/icons-material/LockResetRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

//Styles
import "../changePassword/changePassword.screen.style.css";
import LeftMenu from "../../../components/leftMenu/leftMenu.component";
import TopNav from "../../../components/topNav/topNav.component";
import Footer from "../../../components/footer/footer.component";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { IChangePasswordModel } from "../../../interface/Request/IChangePasswordModel";
import { ToastSeverity } from "../../../constants/toastSeverity.contants";
import { ToastContext } from "../../../context/toast.context";
import { IApiResponse } from "../../../interface/Response/IApiResponse";
import UserService from "../../../services/user/user.service";
import { IAlertDialogConfig } from "../../../interface/IAlertDialogConfig";
import FormHelperText from "@mui/material/FormHelperText";
import AlertDialog from "../../../components/alertDialog/alertDialog.component";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
} from "../../../constants/DBConstants.contant";

const ChangePassword: FunctionComponent<PageProps> = ({ title }) => {
  //Navigation
  const navigate = useNavigate();

  //Password Show Hide
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const _userService = new UserService({});
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    document.title = title;
  }, []);

  const InitialValues: IChangePasswordModel = {
    oldPassword: "",
    newPassword: "",
  };

  const ChangePasswordSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .required("This field is required")
      .min(8, "Should be minimum of 8 characters"),
    newPassword: yup
      .string()
      .required("New Password is required.")
      .min(8, "Should be minimum of 8 characters"),
  });

  const _handleChangePassword = async (values: IChangePasswordModel) => {
    try {
      if (confirmPassword != values.newPassword) {
        setToastConfig(
          ToastSeverity.Error,
          "Password & confirm password doesn't match.",
          true
        );
      } else if (values.oldPassword === values.newPassword) {
        setToastConfig(
          ToastSeverity.Error,
          "New Password & old password shouldn't be the same.",
          true
        );
      } else {
        var response: IApiResponse = await _userService.changePassword({
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        });
        if (!response.isSuccess) {
          setToastConfig(ToastSeverity.Error, response.message, true);
        } else {
          setAlertConfig({
            description: response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              navigate("/dashboard");
            },
          });
        }
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{ ...InitialValues }}
      validationSchema={ChangePasswordSchema}
      onSubmit={(values, { setSubmitting }) => {
        _handleChangePassword(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="height100">
          <Box className="pageMain">
            <Box className="pageLeft">
              <LeftMenu />
            </Box>
            <Box className="pageRight">
              <Box className="pageHead">
                <TopNav title={title.split("|")[1].trim()} />
              </Box>
              <Box className="pageView">
                <Box className="pageViewBody">
                  <Box>
                    <Grid item xs={12} md={5} lg={5}>
                      <Box className="accountBodyRight height100">
                        <Box className="commonCard">
                          <Card className="height100">
                            <CardContent>
                              {/* <Typography className="accountTitle">
                                Change Password
                              </Typography> */}
                              <Box className="commonBgCard changePassworgHead">
                                <LockResetRoundedIcon />
                              </Box>
                              <Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <FormControl variant="outlined" fullWidth>
                                      <TextField
                                        variant="outlined"
                                        id="oldPassword"
                                        type={"password"}
                                        label="Current Password"
                                        onChange={handleChange}
                                        value={
                                          values.oldPassword
                                            ? values.oldPassword
                                            : ""
                                        }
                                      />
                                      {errors.oldPassword &&
                                        touched.oldPassword && (
                                          <FormHelperText className="errorMessage">
                                            {errors.oldPassword}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <FormControl variant="outlined" fullWidth>
                                      <TextField
                                        id="newPassword"
                                        type={"password"}
                                        label="New Password"
                                        onChange={handleChange}
                                        value={values.newPassword}
                                        variant="outlined"
                                      />
                                      {errors.newPassword &&
                                        touched.newPassword && (
                                          <FormHelperText className="errorMessage">
                                            {errors.newPassword}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <FormControl variant="outlined" fullWidth>
                                      <InputLabel htmlFor="outlined-adornment-password">
                                        Re-enter Password
                                      </InputLabel>
                                      <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showPassword ? (
                                                <VisibilityOff />
                                              ) : (
                                                <Visibility />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        label="Re-enter Password"
                                        onChange={(event: any) => {
                                          setConfirmPassword(
                                            event.target.value
                                          );
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Box>
                            </CardContent>
                            <CardActions>
                              <Button
                                type="submit"
                                variant="contained"
                                className="primaryFillBtn width100"
                                disabled={isSubmitting}
                              >
                                <span>Submit</span>
                              </Button>
                            </CardActions>
                          </Card>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                </Box>
                <Box className="pageViewFooter">
                  <Footer />
                </Box>
              </Box>
            </Box>
          </Box>
          {alertConfig && alertConfig.isShow && (
            <AlertDialog
              alertConfig={alertConfig}
              callBack={alertConfig.callBack}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

export default ChangePassword;
