import React, {
  FunctionComponent,
  JSXElementConstructor,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

import AuthService from "../../services/offers/offers.service";
import { useNavigate } from "react-router-dom";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { ToastContext } from "../../context/toast.context";
import { Formik } from "formik";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

//Links

//CSS
import "../dbTransactionHistory/dbTransactionHistory.screen.style.css";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import * as yup from "yup";
import {
  API_ERROR_STANDARD_MESSAGE,
  RoleType,
} from "../../constants/DBConstants.contant";
import dayjs from "dayjs";
import ApplicationHelperService from "../../services/ApplicationHelperService";
import { useSelector } from "react-redux";
import { LoadingContext } from "../../context/loading.context";
//Modal
//Icons
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import TransactionService from "../../services/transaction/transaction.service";
import { IAdminTransactionsResponseModel } from "../../interface/Response/IAdminTransactionsResponseModel";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import SellIcon from "@mui/icons-material/Sell";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import PaymentIcon from "@mui/icons-material/Payment";
import Divider from "@mui/material/Divider";

const DBTransactionHistory: FunctionComponent<PageProps> = ({ title }) => {
  const columns: GridColDef[] = [
    { field: "appId", headerName: "User Id", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phoneCode", headerName: "Phone Code", width: 100 },
    { field: "phoneNumber", headerName: "Phone Number", width: 150 },
    { field: "amount", headerName: "Amount", width: 100 },
    { field: "updatedBy", headerName: "Updated By", width: 200 },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    { field: "approvedOn", headerName: "Approved On", width: 150 },
  ];

  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const _applicationHelperService = new ApplicationHelperService({});
  const _authService = new AuthService({});
  const navigate = useNavigate();
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const _transactionService = new TransactionService({});
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const { user } = userInfo;
  const { setLoading } = useContext(LoadingContext);
  const ADMIN_TRANSACTION_INITIALS: IAdminTransactionsResponseModel = {
    creditCount: 0,
    debitCount: 0,
    creditAmount: 0,
    debitAmount: 0,
    amountWithUs: 0,
    credits: [],
    debits: [],
  };
  const [adminTransactions, setAdminTransactions] = useState<
    IAdminTransactionsResponseModel
  >(ADMIN_TRANSACTION_INITIALS);
  const [readableDate, setReadableDate] = useState<any>({
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    document.title = title;
  }, []);

  const InitialValues = {
    fromDate: dayjs,
    toDate: dayjs,
  };

  const OffersSchema = yup.object().shape({
    fromDate: yup.date().required("Valid From is required."),
    toDate: yup.date().required("Valid Till is required."),
  });

  const retrieveDBTransactionHistory = async (values: any) => {
    setLoading(true);
    try {
      var apiResponse: IApiResponse = await _transactionService.adminTransactions(
        values.fromDate,
        values.toDate
      );
      setAdminTransactions(
        apiResponse.response as IAdminTransactionsResponseModel
      );
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }

    setLoading(false);
  };

  function DataGridCreditsTitle() {
    return (
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box className="adminTransDesc">
          {readableDate.fromDate && readableDate.toDate && (
            <Typography
              variant="h6"
              style={{
                paddingLeft: 10,
                fontSize: 14,
                fontWeight: 600,
                justifyContent: "center",
              }}
            >
              All Admin Credit Transactions From {readableDate.fromDate} To{" "}
              {readableDate.toDate}
            </Typography>
          )}
        </Box>

        <Box>
          <GridToolbar />
        </Box>
      </Box>
    );
  }

  function DataGridDebitsTitle() {
    return (
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box className="adminTransDesc">
          {readableDate.fromDate && readableDate.toDate && (
            <Typography
              variant="h6"
              style={{ paddingLeft: 10, fontSize: 14, fontWeight: 600 }}
            >
              All Admin Debit Transactions {readableDate.fromDate} To{" "}
              {readableDate.toDate}
            </Typography>
          )}
        </Box>

        <Box>
          <GridToolbar />
        </Box>
      </Box>
    );
  }

  return (
    <Box className="pageMain offersPage">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll dse">
            <Box className="commonBgCard saperatorSpacing">
              {[RoleType.Admin, RoleType.SuperAdmin].includes(user.roleId) && (
                <Formik
                  initialValues={{ ...InitialValues }}
                  validationSchema={OffersSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    retrieveDBTransactionHistory(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Start Date"
                              className="commonDatePicker commonInputTopSpacing"
                              format="DD-MMM-YYYY"
                              onChange={(newValue: any) => {
                                setAdminTransactions(
                                  ADMIN_TRANSACTION_INITIALS
                                );
                                setFieldValue(
                                  "fromDate",
                                  dayjs(newValue).format("DD-MMM-YYYY")
                                );
                                setReadableDate({
                                  ...readableDate,
                                  fromDate: dayjs(newValue).format(
                                    "MMMM DD, YYYY"
                                  ),
                                });
                              }}
                              value={values.fromDate}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="End Date"
                              className="commonDatePicker commonInputTopSpacing"
                              format="DD-MMM-YYYY"
                              onChange={(newValue: any) => {
                                setAdminTransactions(
                                  ADMIN_TRANSACTION_INITIALS
                                );
                                setFieldValue(
                                  "toDate",
                                  dayjs(newValue).format("DD-MMM-YYYY")
                                );
                                setReadableDate({
                                  ...readableDate,
                                  toDate: dayjs(newValue).format(
                                    "MMMM DD, YYYY"
                                  ),
                                });
                              }}
                              value={values.toDate}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={3} lg={4} className="padT24">
                          <FormControl fullWidth>
                            <Button
                              type="submit"
                              variant="contained"
                              className="primaryFillBtn sectionBtn floatR"
                            >
                              <span>
                                <DownloadForOfflineRoundedIcon /> &nbsp; Get
                                Results
                              </span>
                            </Button>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              )}
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={2} xl={2}>
                  <Box className="saperatorSpacing">
                    <Card className="appIdCard dashboardCards">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                Total Credit count
                              </Typography>
                              <Typography className="dashboardCardValue">
                                {adminTransactions.creditCount}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <AccountCircleRoundedIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={2} xl={2}>
                  <Box className="saperatorSpacing">
                    <Card className="referralCard dashboardCards">
                      <CardContent>
                        <Grid
                          style={{ cursor: "pointer" }}
                          container
                          spacing={2}
                          onClick={() => navigate("/referralList")}
                        >
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                Total Debit count
                              </Typography>
                              <Typography className="dashboardCardValue">
                                {adminTransactions.debitCount}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <PeopleAltRoundedIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={2} xl={2}>
                  <Box className="saperatorSpacing">
                    <Card className="balanceCard dashboardCards">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                Credit Amount
                              </Typography>
                              <Typography className="dashboardCardValue">
                                {adminTransactions.creditAmount}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <SellIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={2} xl={2}>
                  <Box className="saperatorSpacing">
                    <Card className="debitAmount dashboardCards">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                Debit Amount
                              </Typography>
                              <Typography className="dashboardCardValue">
                                {adminTransactions.debitAmount}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <AccountBalanceWalletRoundedIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={2} xl={2}>
                  <Box className="saperatorSpacing">
                    <Card className="amountWithUs dashboardCards">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                Amount with Us
                              </Typography>
                              <Typography className="dashboardCardValue">
                                {adminTransactions.amountWithUs}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <PriceChangeIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={2} xl={2}>
                  <Box className="saperatorSpacing">
                    <Card className="dummy dashboardCards">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle"></Typography>
                              <Typography className="dashboardCardValue"></Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <PaymentIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {adminTransactions.credits.length > 0 && (
              <DataGrid
                className="commonDataTable"
                getRowId={(row) => row.sNo}
                rows={adminTransactions.credits}
                columns={columns}
                getRowHeight={() => "auto"}
                slots={{
                  toolbar: DataGridCreditsTitle,
                }}
                rowCount={adminTransactions.credits.length}
                density="compact"
                hideFooterPagination={true}
                hideFooter={true}
                autoHeight={true}
              />
            )}

            <br />
            {adminTransactions.debits.length > 0 && (
              <DataGrid
                className="commonDataTable"
                getRowId={(row) => row.sNo}
                rows={adminTransactions.debits}
                columns={columns}
                getRowHeight={() => "auto"}
                slots={{
                  toolbar: DataGridDebitsTitle,
                }}
                rowCount={adminTransactions.debits.length}
                density="compact"
                hideFooterPagination={true}
                hideFooter={true}
                autoHeight={true}
              />
            )}
          </Box>

          <Box className="pageViewFooter">
            <Footer />
          </Box>
          {alertConfig && alertConfig.isShow && (
            <AlertDialog
              alertConfig={alertConfig}
              callBack={alertConfig.callBack}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default DBTransactionHistory;
