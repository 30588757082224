import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Component } from "react";
import React from "react";
import { Outlet } from "react-router-dom";

const ProtectedRoute = (props: {
  children: React.ReactNode;
  allowedRoles: number[];
}): JSX.Element => {
  const { children } = props;
  const { userInfo } = useSelector((state: any) => state.authReducer);
  return userInfo && userInfo.user && userInfo.user.userId != "" ? (
    props.allowedRoles.includes(userInfo.user.roleId) ? (
      <>{children}</>
    ) : (
      <Navigate replace={true} to="/dashboard" />
    )
  ) : (
    <Navigate replace={true} to="/" />
  );
};

export default ProtectedRoute;
