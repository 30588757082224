import { ILoginModel } from "../interface/Request/ILoginModel";
import HttpHelperService from "../services/httpHelper.service";
import {
  AUTH_REQUESTED,
  AUTH_ERROR,
  COUNTRIES_SUCCESS,
  CONFIG_SETTINGS,
  WITHDRAWAL_ACCOUNT_TYPE_SUCCESS,
  WALLET_BALANCE_SUCCESS,
} from "../constants/Reducer.constant";
import { Action, Dispatch } from "redux";
import { IApiResponse } from "../interface/Response/IApiResponse";
import ApplicationHelperService from "../services/ApplicationHelperService";
import {
  CONFIG,
  COUNTRY_CONFIG,
  GET_GAME_SETTINGS,
  GET_RECHARGE_PAYMENTS,
  WALLET_BALANCE,
} from "../constants/EndPoints.constant";
import { ICountryModel } from "../interface/BusinessModels/ICountryModel";
import { ILookupModel } from "../interface/BusinessModels/ILookupModel";
import { IGameSettings } from "../interface/BusinessModels/IGameSettings";
import { IRechargePayments } from "../interface/BusinessModels/IRechargePayments";
import {
  WITHDRAWALACCOUNTTYPE_CONFIG,
  PAYMENT_SETTINGS,
} from "../constants/DBConstants.contant";
import { IWalletBalanceModel } from "../interface/BusinessModels/IWalletBalanceModel";

const _httpHelperService = new HttpHelperService({});
const _applicationHelperService = new ApplicationHelperService({});

export const getCountries = () => async (dispatch: Dispatch<Action>) => {
  try {
    _httpHelperService
      .get(COUNTRY_CONFIG)
      .then(async (response: IApiResponse) => {
        if (response.isSuccess) {
          dispatch({
            type: COUNTRIES_SUCCESS,
            payload: response.response as ICountryModel[],
          });
        }
      })
      .catch((error) => {
        console.log("Look Up Actions", error);
      });
  } catch (error) {
    console.log("Look Up Actions", error);
  }
};

export const getConfigs = (type: string) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    _httpHelperService
      .get(`${CONFIG}?type=${type}`)
      .then(async (response: IApiResponse) => {
        if (response.isSuccess) {
          switch (type.toLowerCase()) {
            case WITHDRAWALACCOUNTTYPE_CONFIG.toLowerCase():
              dispatch({
                type: WITHDRAWAL_ACCOUNT_TYPE_SUCCESS,
                payload: response.response as ILookupModel[],
              });
              break;
          }
        }
      })
      .catch((error) => {
        console.log("Look Up Actions", error);
      });
  } catch (error) {
    console.log("Look Up Actions", error);
  }
};

export const getConfigSettings = (type: string, gameConfigId: number) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    _httpHelperService
      .get(`${GET_GAME_SETTINGS}?type=${type}&gameConfigId=${gameConfigId}`)
      .then(async (response: IApiResponse) => {
        if (response.isSuccess) {
          switch (type.toLowerCase()) {
            case PAYMENT_SETTINGS.toLowerCase():
              dispatch({
                type: CONFIG_SETTINGS,
                payload: response.response as IGameSettings[],
              });
              break;
          }
        }
      })
      .catch((error) => {
        console.log("Look Up Actions", error);
      });
  } catch (error) {
    console.log("Look Up Actions", error);
  }
};

export const getWalletBalance = () => (dispatch: Dispatch<Action>) => {
  try {
    _httpHelperService
      .get(`${WALLET_BALANCE}`)
      .then(async (response: IApiResponse) => {
        if (response.isSuccess) {
          dispatch({
            type: WALLET_BALANCE_SUCCESS,
            payload: response.response as IWalletBalanceModel,
          });
        }
      })
      .catch((error) => {
        console.log("Look Up Actions", error);
      });
  } catch (error) {
    console.log("Look Up Actions", error);
  }
};
