import { Component } from "react";
import HttpHelperService from "../httpHelper.service";
import { IUserRegistrationModel } from "../../interface/BusinessModels/IUserRegistrationModel";
import {
  CONFIG,
  GET_CONTACT_HELP,
  CONVERT_CURRENCY,
  CONTACT_HELP,
  MANAGE_SETTINGS,
  GET_GAME_SETTINGS,
  COUNTRY_CONFIG_ALL,
  UPDATE_COUNTRY_CONFIG,
  RECHARGE_PAYMENTS,
  GET_RECHARGE_PAYMENTS,
  UPDATE_HELP,
  GET_ALLRECHARGE_PAYMENTS,
  GET_LOTTERY_SUMMARY,
  UPLOAD_GAME_RESULT_IMAGE,
} from "../../constants/EndPoints.constant";

import {
  IHelpModel,
  IHelpModalList,
} from "../../interface/BusinessModels/IHelpModel";
import { IRechargePayments } from "../../interface/BusinessModels/IRechargePayments";
import { ICountryModel } from "../../interface/BusinessModels/ICountryModel";

class LookupService extends Component {
  _httpHelperService = new HttpHelperService({});

  getConfigs = async (type: string) => {
    return await this._httpHelperService.get(`${CONFIG}?type=${type}`);
  };

  getAllCountries = async () => {
    return await this._httpHelperService.get(`${COUNTRY_CONFIG_ALL}`);
  };

  updateCountry = async (countryModel: ICountryModel) => {
    return await this._httpHelperService.post(
      `${UPDATE_COUNTRY_CONFIG}`,
      countryModel
    );
  };

  getGameSettings = async (type: string, gameConfigId: number) => {
    return await this._httpHelperService.get(
      `${GET_GAME_SETTINGS}?type=${type}&gameConfigId=${gameConfigId}`
    );
  };

  convertCurrency = async (request: any) => {
    return await this._httpHelperService.post(`${CONVERT_CURRENCY}`, request);
  };

  help = async (helpModel: IHelpModel) => {
    return await this._httpHelperService.post(CONTACT_HELP, helpModel);
  };

  GethelpList = async () => {
    return await this._httpHelperService.get(GET_CONTACT_HELP);
  };

  manageSettings = async (gameConfigId: number, settings: any) => {
    return await this._httpHelperService.post(
      `${MANAGE_SETTINGS}?gameConfigId=${gameConfigId}`,
      settings
    );
  };

  rechargePayments = async (rechargePayments: IRechargePayments) => {
    return await this._httpHelperService.post(
      RECHARGE_PAYMENTS,
      rechargePayments
    );
  };

  getrechargePayments = async (rechaargeId: string) => {
    return await this._httpHelperService.get(
      `${GET_RECHARGE_PAYMENTS}?rechaargeId=${rechaargeId}`
    );
  };

  getAllRechargePayments = async (type: number) => {
    return await this._httpHelperService.get(
      `${GET_ALLRECHARGE_PAYMENTS}?rechargeStatusId=${type}`
    );
  };

  getLotterySummary = async (id: number) => {
    return await this._httpHelperService.get(
      `${GET_LOTTERY_SUMMARY}?gameId=${id}`
    );
  };

  updateHelp = async (contactHelp: IHelpModalList) => {
    return await this._httpHelperService.post(UPDATE_HELP, contactHelp);
  };

  uploadGameResultImage = async (body: FormData) => {
    return await this._httpHelperService.postFormData(
      UPLOAD_GAME_RESULT_IMAGE,
      body
    );
  };
}

export default LookupService;
