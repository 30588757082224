import { Component } from "react";
import HttpHelperService from "../httpHelper.service";
import { DOWNLOAD_RESULT_CHART } from "../../constants/EndPoints.constant";

class ExportService extends Component {
  _httpHelperService = new HttpHelperService({});

  downloadResultChart = async (gameId: number) => {
    return await this._httpHelperService.downloadFile(
      `${DOWNLOAD_RESULT_CHART(gameId)}`
    );
  };
}

export default ExportService;
